// actions.ts
export const RESET_STORE = 'RESET_STORE';

export interface ResetStoreAction {
  type: typeof RESET_STORE;
}

export const resetStore = (): ResetStoreAction => ({
  type: RESET_STORE,
});
