import React from 'react';
import { useAppSelector } from 'hooks/redux';
import formatLocalCurrency from 'utils/formatLocalCurrency';


/**
 *
 * @param details
 * @description Confirm Details Component
 * @returns
 */

const ConfirmDetails = ({
    details
}: {
    details?: {
        amount?: string,
        name?: string,
        mobile?: string,
    }
}) => {
    const wallet = useAppSelector(state => state.wallet as any)

    return (
        <div className=" min-w-max w-full border rounded-[1.2rem]  grid gap-[3.6rem]   border-[#B2B3B3] md:pt-[5.7rem] md:pb-[6.8rem] md:pl-[8.9rem] md:pr-[8.9rem] px-[2rem] py-[4rem] ">
            <section className="flex justify-between items-center gap-4">
                <h5 className="md:text-[1rem] text-[.875rem] font-[500] text-[#616161] font-nunito w-full">
                    Credit Amount
                </h5>
                <h6 className="text-[var( --gray-scale-text)] font-[600] text-[18px] text-right font-nunito">
                {`${formatLocalCurrency(wallet.walletTranferDetails?.data?.amount)}`}
                </h6>
            </section>
            <section className="flex justify-between items-center gap-4 w-full">
                <h5 className="md:text-[1rem] text-[.875rem] font-[500] text-[#616161] font-nunito">
                    Receiver’s Name
                </h5>
                <h6 className="text-[var( --gray-scale-text)] font-[600] text-[18px] text-right font-nunito">
                   {`${wallet.walletTranferDetails?.data?.full_name}`}
                </h6>
            </section>
            <section className="flex justify-between gap-4 items-center w-full">
                <h5 className="md:text-[1rem] text-[.875rem] font-[500] text-[#616161] font-nunito">
                    Receiver’s Phone Number
                </h5>
                <h6 className="text-[var( --gray-scale-text)] font-[700] text-[1.12rem] text-right font-nunito">
                   {`${wallet.walletTranferDetails?.data?.mobile_number}`}
                </h6>
            </section>



        </div>

    )

}


export default ConfirmDetails;