import React from 'react'
import style from './header.module.scss'
import { Heading , } from '@chakra-ui/react'



const Header = () => {
    return (

            <header className={style.header}>
                {
                    data.map((item, index) => {
                        return (
                            <div key={index}>
                                <Heading as={'h1'} fontSize={{
                                    base:'.8rem' , lg:'1rem' , '2xl':'1.2rem'
                                }}>{item}</Heading>
                            </div>
                        )
                    })
                }
            </header>


    )
}

export default Header



const data = [
    "product details", "price", "quantity", "subtotal", "action"
]