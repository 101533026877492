import CartWrapper from "./CartWrapper";
import OtherDashboard from "container/dashboard/OtherDashboard";
/**
 * GiftCardPage Component
 *
 * This component represents the main page for gift card functionality.
 * It wraps the CartWrapper component within the OtherDashboard layout.
 *
 * @component
 * @returns {JSX.Element} The rendered GiftCardPage component
 */


import React from "react";

const GiftCardPage = () => {
  return (
    <OtherDashboard>
      <CartWrapper />
    </OtherDashboard>
  );
};

export default GiftCardPage;
