/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";

import style from "./jaralink.module.scss";
import { useAppSelector } from "hooks/redux";
import { Flex, Switch, FormLabel } from "@chakra-ui/react";

const NotificationSwitch = ({
  handleClick,
  isActive,
  title,
}: {
  title: string;
  handleClick: () => void;
  isActive: boolean;
}) => {
  const { user } = useAppSelector((state) => state.auth as any);
  sessionStorage.setItem("user", user?.data);
  sessionStorage.setItem("user_mail", user?.data?.email);
  sessionStorage.setItem("mail", user?.data?.email);
  sessionStorage.setItem("id", user?.data?.id);

  //unmount

  return (
    <Flex justify={"space-between"} className={style.jaralink}>
      <p>{title}</p>
      <FormLabel htmlFor="alerts" m={".6rem"}>
        <Switch
          cursor={"pointer"}
          isChecked={isActive}
          onChange={handleClick}
         

          //   colorScheme="blue"

          color={"primary"}
          size={{
            base: "md",
            md: "lg",
          }}
        />
      </FormLabel>
    </Flex>
  );
};

export default NotificationSwitch;
