import React from "react";

import style from "./transaction.module.scss";
// import Modal from 'components/successpage/Modal'
// import SuccessMessage from './SuccessMessage'
import formatLocalCurrency from "utils/formatLocalCurrency";
import ModalContainer from "components/Modals/ModalContainer";
import { Flex, useDisclosure, Divider, Text , Center } from "@chakra-ui/react";
import ViewTransactions from "./ViewTransactions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
// import TransactionInfo from "components/nameinfo/TransactionInfo";
import Avartar from "components/nameavartar/Avartar";

interface Prop {
  item: any;
  handleClick: () => void;
}
const MobileFundItem: React.FC<Prop> = ({ item, handleClick = () => {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const handleOpen = (transaction_id: string) => {
    navigate(`/transactions?id=${transaction_id}`);
    onOpen();
  };

  const data: Record<string, string> = {
    Airtime: "/Services/sv-1.svg",
    Data: "/Services/sv-2.svg",
    Internet: "/Services/sv-3.svg",
    Electricity: "/Services/sv-4.svg",
    "Cable TV": "/Services/sv-5.svg",
    Cable: "/Services/sv-5.svg",
    "Gift Card": "/Services/sv-6.svg",
    Gift: "/Services/sv-6.svg",

    "Wallet Funding": "/Services/sv-7.svg",
    Education: "/Services/sv-7.svg",
    Insurance: "/Services/sv-8.svg",
    "Aitime To Cash": "/Services/sv-9.svg",
    Shopping: "/Services/sv-10.svg",
  };

  return (
    <>
      <Flex
        w={"full"}
        // onClick={() => handleOpen(item?.request_id)}
        borderRadius={"md"}
        border={"1px solid"}
        borderColor={"gray.200"}
        padding={".8rem"}
        boxShadow={"sm"}
        gap={".5rem"}
        cursor={"pointer"}
        align={"center"}
      >
        <Avartar
          radius={"five"}
          image={"/Services/sv-7.svg"}
        />
        <Flex as={"section"} align={"center"} w={"full"}>
          <Flex flexDir={"column"} w={"full"}>
            <Flex
              justify={"space-between"}
              align={"center"}
              gap={"1rem"}
              w={"full"}
            >
              <Text as="h1" fontWeight={"700"}>
              {item.payment_type}
              </Text>
              <p
                className={`${style.amount} ${
                  item.transation_type === "debit" ||
                  item.trasaction_type === "debit"
                    ? style.debit
                    : style.credit
                }`}
              >
                {`

              ${
                item.amount
                  ? formatLocalCurrency(item.amount)
                  : formatLocalCurrency(item?.transactions_amount)
              }`}
              </p>
            </Flex>
            <Flex gap={".3rem"} align={"center"}>
              <Text color={"gray.500"} fontSize={".875rem"}>
                {moment(item.date).format("MMMM Do YYYY")}
              </Text>
              <Center height="20px">
                <Divider orientation="vertical" />
              </Center>
              <Text color={"gray.500"} fontSize={".875rem"}>{item.payment_type}</Text>
            </Flex>
          </Flex>
        </Flex>

        {/* ${
            item.transation_type === "debit" || item.trasaction_type === "debit"
              ? "-"
              : ""
          } */}

        {/* <p className={style.type}>{item.payment_type}</p> */}
        {/* <p
        className={`${style.amount} ${
          item.transation_type === "debit" || item.trasaction_type === "debit"
            ? style.debit
            : ""
        }`}
      >
        {`${
          item.transation_type === "debit" || item.trasaction_type === "debit"
            ? "-"
            : ""
        }${
          item.amount
            ? formatLocalCurrency(item.amount)
            : formatLocalCurrency(item?.transactions_amount)
        }`}
      </p> */}
        {/* <div className={style.date_and_time}>
        <p className={style.date}>
          {moment(item.date).format("MMMM Do YYYY")}
        </p>
        <p className={style.time}>{item.time}</p>
      </div>
      <p className={style.reference}>
        {item?.request_id
          ? item?.request_id
          : item?.transactions_mobile_number}
      </p> */}
      </Flex>

      <ModalContainer size="2xl" isOpen={isOpen} onClose={onClose}>
        <ViewTransactions item={item} />
      </ModalContainer>
    </>
  );
};

export default MobileFundItem;
