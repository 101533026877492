import React, { useEffect, useState } from 'react';
import FundViaTable from './FundViaTable/FundViaTable';
import AccountNumberTable from './AccountNumberTable/AccountNumberTable';
import FundingSummary from './FundingSummary/FundingSummary';
import { useAppSelector } from 'hooks/redux';
import formatLocalCurrency from 'utils/formatLocalCurrency';




// const account: {
//     accountNumber: string;
//     accountName: string;
//     bank: string;

// }[] = [
//         {
//             accountName: "Adewolu Olayemi",
//             accountNumber: "3424353749",
//             bank: "Providus Bank",

//         },
//         {
//             accountName: "Adewolu Olayemi",
//             accountNumber: "4535353534",
//             bank: "GTB Bank",

//         }

//     ]

// const data: {
//     title: string;
//     description: string;
//     svg: string
// }[] = [
//         {
//             title: "Pay with wallet balance",
//             description: "You have N50,000.00",
//             svg: '/PaymentOptions/wallet.svg'
//         },
//         {
//             title: "JOHN DOE",
//             description: "******************5004        09/2024",
//             svg: '/Services/subscriptions.svg'
//         }

//     ]

const ServicesPayment = ({
  onClick,
  title,
}: {
  onClick: () => void;
  title?: string;
}) => {

    const wallet = useAppSelector(state => state.wallet as any)
    const [cardData, setCardData] = useState<any[]>([])
    const { user = {} } = useAppSelector((state: any) => state.auth as any)
    const [data, setData] = useState<any[]>([])




    useEffect(() => {
        if (user?.data?.virtual_account) {
            //filter empty bank

            setCardData(user.data.virtual_account)
        }

    }, [user])




  //   const data : any[] = [
  //         {
  //             provider: "Pay with wallet balance",
  //             description: `You have ${formatLocalCurrency(user?.wallet?.balance)} available`,
  //             provider_image: '/PaymentOptions/wallet.svg'
  //         },
  //         {
  //             provider: "JOHN DOE",
  //             description: "******************5004        09/2024",
  //             provider_image: '/Services/subscriptions.svg'
  //         }

  //     ]
  useEffect(() => {
    const _wallet = wallet?.paymentOptions?.wallet;
    const data = [
      {
        provider: "Pay with wallet balance",
        description: `You have ${formatLocalCurrency(
          _wallet?.balance
        )} available`,
        provider_image: "/PaymentOptions/wallet.svg",
      },

      ...wallet?.paymentOptions?.cards?.information?.map((item: any) => {
        return {
          ...item,
          provider: item?.name ? item.name : item.platform,
          description: `************ ${item.last4}               ${item.exp_month}/${item.exp_year}`,
          provider_image: "/Services/subscriptions.svg",
        };
      }),
    ];
    if (data) {
      //set unique data
      //dont repeat signature
      const uniqueData = data.filter((item: any, index: number) => {
        return (
          data.findIndex((item2: any) => item2.signature === item.signature) ===
          index
        );
      });
      setData(uniqueData);
    }
  }, [
    wallet?.paymentOptions?.cards?.information,
    wallet?.paymentOptions?.wallet,
  ]);

  return (
    <div className="pt-[2.9375rem] grid lg:flex xl:gap-[4.875rem] gap-[2.375rem]">
      <div className="w-full grid gap-[5rem]">
        <FundViaTable heading={""} data={data} />
        {title ? null : (
          <div>
            <h1 className="text-[var( --gray-scale-text)] text-[1.8rem] font-bold font-nunito">
              OR
            </h1>
            <AccountNumberTable account={cardData} />
          </div>
        )}
      </div>
      <div className="xl:w-[23.75rem] xl:min-w-[23.75rem] w-full h-[max-content] pb-[0.5625rem]">
        <FundingSummary
          onClick={onClick}
          title={"Payment Summary"}
          buttonText={title ? title : "Proceed To Pay"}
        />
      </div>
    </div>
  );
};

export default ServicesPayment;
