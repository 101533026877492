import React, { useEffect, useContext } from "react";
import DashboardContext from "context/Dashboard/DashboardContext";
import style from "./transaction.module.scss";
import Header from "components/Transactions/Header";
import TransactionList from "components/Transactions/TransactionList";
import { header, fundingHeader } from "./data";
import { useAppSelector } from "hooks/redux";
import { Box } from "@chakra-ui/react";
import { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Show,
  Hide,
} from "@chakra-ui/react";
import FundingList from "components/Transactions/FundingList";
import useUltimateSearch from "utils/useUltimateSearch";
import MobileTransactionList from "components/Transactions/MobileTransactions";
import MobileFundings from "components/Transactions/MobileFundings";

const TransactionsContainer = () => {
  const [fundingHistory, setFundingHistory] = useState<any[]>([]);
  const [transactionHistory, setTransactionHistory] = useState<any[]>([]);

  const {
    setCurrentPagename,
    setBgContentColor,
    setBgSideMenuColor,
    setBgTopMenuColor,
  } = useContext(DashboardContext);

  //get transactions

  const { search } = useAppSelector((state) => state.transaction);

  const { transactions, funding } = useAppSelector(
    (state: any) => state.transaction
  );

  const { myData: myFundingData } = useUltimateSearch(funding, search);
  const { myData: myTransactionData } = useUltimateSearch(transactions, search);

  useEffect(() => {
    if (myTransactionData?.length) {
      const spreadArray = [...myTransactionData];
      // //sort by date
      // spreadArray.sort((a: any, b: any) => {
      //   const dateA = new Date(a.date);
      //   const dateB = new Date(b.date);
      //   return dateA.getTime() - dateB.getTime();
      // });

      // spreadArray?.reverse();

      setTransactionHistory(spreadArray);
    }
  }, [myTransactionData]);

  //funding
  useEffect(() => {
    if (myFundingData?.length) {
      const spreadArray = [...myFundingData];

      // spreadArray?.reverse();

      setFundingHistory(spreadArray);
    }
  }, [myFundingData]);

  useEffect(() => {
    setCurrentPagename("Transactions");
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgContentColor ? setBgContentColor("#FFF") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgTopMenuColor ? setBgTopMenuColor("#FFF") : null;
  }, [
    setBgContentColor,
    setBgSideMenuColor,
    setCurrentPagename,
    setBgTopMenuColor,
  ]);

  return (
    <Tabs variant={"solid-rounded"} colorScheme="blue">
      <TabList
        px={{
          base: 4,
          md: 6,
          lg: 8,
          xl: 10,
        }}
        mt={"2rem"}
      >
        <Tab
          _selected={{
            bg: "var(--primary)",
            color: "white",
          }}
        >
          Transactions
        </Tab>
        <Tab
          _selected={{
            bg: "var(--primary)",
            color: "white",
          }}
        >
          Funding
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Box
            w={"full"}
            maxW={"100vw !important"}
            overflowX={"auto"}
            className={style.transactions}
          >
            <Hide below="lg">
              <Header data={header} />
              <TransactionList
                handleClick={() => {}}
                data={transactionHistory as []}
              />
            </Hide>
            <Show below="lg">
              <MobileTransactionList
                handleClick={() => {}}
                data={transactionHistory as []}
              />
            </Show>
          </Box>
        </TabPanel>
        <TabPanel>
          <Box
            w={"full"}
            maxW={"100vw !important"}
            overflowX={"auto"}
            className={style.transactions}
          >
            <Hide below="lg">
              <Header data={fundingHeader} />
              <FundingList handleClick={() => {}} data={fundingHistory as []} />
            </Hide>
            <Show below="lg">
              <MobileFundings
                handleClick={() => {}}
                data={fundingHistory as []}
              />
            </Show>
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default TransactionsContainer;
