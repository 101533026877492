import {
  Help,
  Transactions,
  Logout,
  Subscriptions,
  HomeIcon,
  FundWalletIcon,
  BeneficiariesIcon,
  VirtualAccount,
  Services,
  WalletTransfer,
  Settings,
  Profile,
  Security,
  Card,
  Notifications,
  GiftCards ,
} from "dashboardIcons";

interface sidebarData {
  name: string;
  Icon: any;
  path: string;
}

export const SidebarData: sidebarData[] = [
  {
    name: "Dashboard",
    Icon: HomeIcon,
    path: "/",
  },
  {
    name: "Gift Cards",
    Icon: GiftCards,
    path: "/gift-cards",
  },


  {
    name: "All Services",
    Icon: Services,
    path: "/services",
  },
  {
    name: "Fund Wallet",
    Icon: FundWalletIcon,
    path: "/fund-wallet",
  },
  {
    name: "My Cards",
    Icon: Card,
    path: "/my-cards",
  },
  {
    name: "Wallet Transfer",
    Icon: WalletTransfer,
    path: "/wallet-transfer",
  },
  {
    name: "Transactions",
    Icon: Transactions,
    path: "/transactions",
  },
  {
    name: "Referral & Cahsback",
    Icon: VirtualAccount,
    path: "/referral-and-cashback",
  },
  {
    name: "Subscriptions",
    Icon: Subscriptions,
    path: "/subscriptions",
  },
  {
    name: "Beneficiaries",
    Icon: BeneficiariesIcon,
    path: "/beneficiaries",
  },

  {
    name: "Security",
    Icon: Security,
    path: "/security",
  },
  {
    name: "Profile",
    Icon: Profile,
    path: "/profile",
  },
  {
    name: "Notifications",
    Icon: Notifications,
    path: "/notifications",
  },
  {
    name: "Help & Support",
    Icon: Help,
    path: "/help",
  },
  {
    name: "Logout",
    Icon: Logout,
    path: "/logout",
  },
];

// import {
//     FiHelpCircle,
//     FiHome
// } from 'react-icons/fi'
// import {
//     AiFillFund
// } from 'react-icons/ai'
// import {GiWallet} from 'react-icons/gi'
// import {MdSwapVert ,MdReceipt, MdSettings} from 'react-icons/md'

// import { IconType } from 'react-icons'
// import {FaMoneyCheckAlt , FaCreditCard , FaUser, FaUsers, FaSignOutAlt} from 'react-icons/fa'

// import {
//     Help, Transactions, Logout, Subscriptions,
//     HomeIcon, FundWalletIcon, BeneficiariesIcon,
//     VirtualAccount, Services, WalletTransfer, Settings,
//     Profile
// } from  'dashboardIcons'

// interface sidebarData {
//     name: string;
//     Icon: any;
//     path: string;
// }

// export const SidebarData : sidebarData[] = [
//     {
//         name: 'Dashboard',
//         Icon : HomeIcon,
//         path : '/'

//     },
//     {
//         name: 'All Services',
//         Icon : Services,
//         path : '/services'
//     },
//     {
//         name: 'Fund Wallet',
//         Icon : FundWalletIcon,
//         path : '/fund_wallet'
//     },
//     {
//         name: 'Wallet Transfer',
//         Icon : WalletTransfer,
//         path : '/wallet_transfer'
//     },
//     {
//         name: 'Transactions',
//         Icon : Transactions,
//         path : '/transactions'
//     },
//     {
//         name: 'Virtual Accounts',
//         Icon : VirtualAccount,
//         path : '/virtualaccounts'
//     },
//     {
//         name:'Subscriptions',
//         Icon : Subscriptions ,
//         path : '/subscriptions'

//     },
//     {
//         name:'Beneficiaries',
//         Icon : BeneficiariesIcon,
//         path : '/beneficiaries'
//     },
//     {
//         name:'Settings',
//         Icon : Settings,
//         path : '/settings'

//     },
//     {
//         name : 'Profile',
//         Icon : Profile,
//         path : '/profile'
//     },
//     {
//         name : 'Help & Support',
//         Icon : Help,
//         path : '/help'
//     },
//     {
//         name : 'Logout',
//         Icon : Logout,
//         path : '/logout'

//     }

// ]
