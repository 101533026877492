
import React from 'react'
import MobileItems from './MobileItems'
import style from './list.module.scss'
import { useAppSelector , useAppDispatch } from 'hooks/redux'
import { retrieveProductFromCart } from 'features/Services/GiftCardService/GiftCardServiceSlice'
import { Box } from '@chakra-ui/react'


const MobileList = () => {
  const {cart} = useAppSelector(state => state.giftCardService)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(retrieveProductFromCart())
  }, [dispatch])

  return (
 <Box>
     <Box as= "section"  className={style.list}>
        {
          cart?.map((item : any, index:number) => {
            return (
             <MobileItems key={index} item={item}  />
            )
          })
        }
    </Box>
    </Box>

  )
}

export default MobileList


const list = [
    {
        image: "/GiftCard/giftcard10$.svg",
        title: "Blue Flower Print Crop Top Blue Flower Print Crop Top",
        price: "$29.00",
        subtotal: "$29.00",

    },
    {
        image: "/GiftCard/giftcard100$.svg",
        title: "Blue Flower Print Crop Top Blue Flower Print Crop Top",
        price: "$29.00",
        subtotal: "$29.00",
    },

    {
        image: "/GiftCard/giftcard100$.svg",
        title: "Blue Flower Print Crop Top Blue Flower Print Crop Top",
        price: "$29.00",
        subtotal: "$29.00",
    },

]

