import React, { useState, useEffect } from "react";
import { Button, Grid } from "@chakra-ui/react";
import Loader from "components/Spinner/Spinner";
import { useGetSubscriptionsQuery } from "features/Subscription/SubscriptionApiSlice";
import {
  Table,
  Tr,
  Td,
  Thead,
  Tbody,
  Th,
  TableContainer,
  Text,
  Flex,
  Image,
  Icon,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { SVGS } from "constants/SVGS";
import { RxDividerVertical } from "react-icons/rx";
import formatTimestamp from "utils/formatTimestamp";
import CurrencyConverter from "utils/CurrencyConverter";
import { MdOutlineUpdate } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import ModalContainer from "components/Modals/ModalContainer";
import DeleteSubscription from "./DeleteSubscription";
import UpdateSubscription from "./UpdateSubscription";

const SubscriptionList = () => {
  const { data, isLoading } = useGetSubscriptionsQuery(undefined);

  const [subscriptions, setSubscriptions] = useState<
    {
      id: number;
      email: string;
      mobile_number: string;
      user_id: string;
      category: string;
      service_id: string;
      variation_code: string;
      amount: string;
      type: string;
      date_created: string;
      due_date: string;
      status: string;
      payment_option: string;
      created_at: string;
      updated_at: string;
      variant: string;
      cron_counter: string;
      success_counter: string;
      failed_counter: string;
    }[]
  >([]);

  useEffect(() => {
    setSubscriptions(data?.data?.original?.data);
  }, [data]);

  return (
    <Grid
      padding={{
        base: "1rem 0",
        md: "2rem 0",
        lg: "2rem 0",
        xl: "2rem 0",
      }}
    >
      {isLoading ? <Loader /> : null}
      {subscriptions?.length ? (
        <TableContainer>
          <Table variant="simple">
            <Thead
              display={{
                base: "none",
                md: "table-header-group",
              }}
            >
              <Tr>
                <Th>Subscription</Th>

                <Th>Amount</Th>
                <Th>Payment Type</Th>
                <Th>Status</Th>
                <Th isNumeric>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {subscriptions?.map((sub, index) => {
                return (
                  <>
                    <Row sub={sub} key={index} />
                    <RowMobile sub={sub} key={index} />
                  </>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : null}
    </Grid>
  );
};

export default SubscriptionList;

const Row = ({ sub }: { sub: any }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  return (
    <>
      <Tr
        display={{
          base: "none",
          md: "table-row",
        }}
      >
        <Td>
          <Flex gap={".2rem"} align={"center"}>
            <Image
              width={{
                base: "1.5rem",
                md: "2rem",
              }}
              height={{
                base: "1.5rem",
                md: "2rem",
              }}
              src={
                SVGS[sub.category as string]
                  ? SVGS[sub.category as string]
                  : "/Beneficiaries/thunder.svg"
              }
              alt={sub.category}
            />
            <Flex flexDir={"column"} gap={".2rem"}>
              <Text textTransform={"capitalize"} fontWeight={"bold"}>
                {
                  // remove - from all string
                  sub.service_id.replace(/-/g, " ")
                }
              </Text>
              <Flex gridTemplateColumns={"repeat(3 , 1fr)"}>
                <Text fontSize={"xs"}>{formatTimestamp(sub.due_date)}</Text>
                <Icon as={RxDividerVertical} />
                <Text textTransform={"capitalize"} fontSize={"xs"}>
                  {sub.type}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Td>

        <Td>{CurrencyConverter(sub.amount)}</Td>
        <Td>{sub.payment_option === "1" ? "Wallet" : "Card"}</Td>
        <Td>
          <Button colorScheme="whatsapp" size={"sm"} fontWeight={"400"}>
            {sub.status === "initiate" ? "Active" : "In-Active"}
          </Button>
        </Td>
        <Td isNumeric>
          <Flex gap={"1rem"} justify={"flex-end"}>
            {/* <IconButton
          size={"sm"}
          icon={<MdOutlineUpdate />}
          colorScheme={"blue"}
          aria-label="Update"
          onClick={onOpen}
        /> */}
            <IconButton
              icon={<MdDelete />}
              colorScheme={"red"}
              aria-label="Delete"
              size={"sm"}
              onClick={onOpenDelete}
            />
          </Flex>
        </Td>
      </Tr>
      <ModalContainer isOpen={isOpen} onClose={onClose} size="lg">
        <UpdateSubscription item={sub} />
      </ModalContainer>
      <ModalContainer isOpen={isOpenDelete} onClose={onCloseDelete} size="lg">
        <DeleteSubscription item={sub} onClose={onCloseDelete} />
      </ModalContainer>
    </>
  );
};

// Mobile
const RowMobile = ({ sub }: { sub: any }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  return (
    <>
      <Tr
        display={{
          base: "table-row",
          md: "none",
        }}
      >
        <Td>
          <Flex gap={".2rem"} align={"center"}>
            <Image
              width={{
                base: "1.5rem",
                md: "2rem",
              }}
              height={{
                base: "1.5rem",
                md: "2rem",
              }}
              src={
                SVGS[sub.category as string]
                  ? SVGS[sub.category as string]
                  : "/Beneficiaries/thunder.svg"
              }
              alt={sub.category}
            />
            <Flex flexDir={"column"} gap={".2rem"}>
              <Flex align={"center"} gap={".3rem"}>
                <Text textTransform={"capitalize"} fontWeight={"bold"}>
                  {
                    // remove - from all string
                    sub.service_id.replace(/-/g, " ")
                  }
                </Text>
              </Flex>
              <Flex align={"center"}>
                <Text fontSize={"xs"}>{formatTimestamp(sub.due_date)}</Text>
                <Icon as={RxDividerVertical} />
                <Text textTransform={"capitalize"} fontSize={"xs"}>
                  {sub.type}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Td>
        {/*
        <Td>{CurrencyConverter(sub.amount)}</Td>
        <Td>{sub.payment_option === "1" ? "Wallet" : "Card"}</Td>
        <Td>
          <Button colorScheme="whatsapp" size={"sm"} fontWeight={"400"}>
            {sub.status === "initiate" ? "Active" : "In-Active"}
          </Button>
        </Td> */}
        <Td isNumeric>
          <Flex gap={"1rem"} justify={"flex-end"} align={"center"}>
            {/* <IconButton
          size={"sm"}
          icon={<MdOutlineUpdate />}
          colorScheme={"blue"}
          aria-label="Update"
          onClick={onOpen}
        /> */}
            <Grid gap={".2rem"}>
              <Text fontWeight={"600"} fontSize={".875rem"}>
                {CurrencyConverter(sub.amount)}
              </Text>
              {/* <Button colorScheme="whatsapp" size={"sm"} fontWeight={"400"}>
                {sub.status === "initiate" ? "Active" : "In-Active"}
              </Button> */}
              <Text fontSize={"sm"} color={"gray.600"}>
                {sub.payment_option === "1" ? "Wallet" : "Card"}
              </Text>
            </Grid>
            <IconButton
              icon={<MdDelete />}
              colorScheme={"red"}
              aria-label="Delete"
              size={"sm"}
              onClick={onOpenDelete}
            />
          </Flex>
        </Td>
      </Tr>
      <ModalContainer isOpen={isOpen} onClose={onClose} size="lg">
        <UpdateSubscription item={sub} />
      </ModalContainer>
      <ModalContainer isOpen={isOpenDelete} onClose={onCloseDelete} size="lg">
        <DeleteSubscription item={sub} onClose={onCloseDelete} />
      </ModalContainer>
    </>
  );
};
