import {
    createContext
} from 'react'

interface IContext {
    currentPagename: string,
    currentId?: number,
    setCurrentId?: (id: number) => void,
    setCurrentPagename: (name: string) => void,
    currentPath?: string,
    setCurrentPath?: (path: string) => void,
    currentUrl?: string,
    bgContentColor?: string,
    bgTopMenuColor?: string,
    bgSideMenuColor?: string,
    setBgContentColor?: (color: string) => void,
    setBgTopMenuColor?: (color: string) => void,
    setBgSideMenuColor?: (color: string) => void,



}

const DashboardContext  = createContext<IContext>({
    currentPagename: 'Dashboard',
    setCurrentPagename: (name: string) => {},
    currentPath: '',
    setCurrentPath: (path: string) => {},
    currentUrl: '',
    bgContentColor: '',
    bgTopMenuColor: '',
    bgSideMenuColor: '',




  });

export default DashboardContext