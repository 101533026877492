import React from "react";
import style from "./cart.module.scss";
import Header from "./Header";
import List from "./List";
import CartBottom from "./CartBottom";
import MailGiftCard from "./MailGiftcard/MailGiftcard";
import { Grid, Text, Icon, Button } from "@chakra-ui/react";

import { IoMdCart } from "react-icons/io";
import MobileList from "./MobileList";

interface Props {
  onClick: () => void;
  setStep?: (steps: "1") => void;
}

const CartContainer: React.FC<Props> = ({ onClick, setStep }) => {
  let cart = localStorage.getItem("cart");
  if (cart) {
    cart = JSON.parse(cart);
  }
  return cart?.length ? (
    <main className={style.cart}>
      <Grid maxW={"98vw"} overflowX={"auto"} w={"100%"} display={{
        base: "none",
        md: "grid",
      }}>
        <Header />
        <List />
      </Grid>
      <Grid display={{
        base: "block",
        md: "none",
      }}>
        <MobileList />
      </Grid>
      <MailGiftCard />
      <Grid
        padding={{
          base: "1rem",
          lg: "",
        }}
      >
        <CartBottom setStep={setStep} onClick={onClick} />
      </Grid>
    </main>
  ) : (
    <Grid placeContent={"center"} placeItems={"center"} height={"20rem"}>
      <Icon as={IoMdCart} fontSize={"5rem"} color={"var(--primary)"} />
      <Text>No item in cart</Text>
      <Button
        onClick={() => (setStep ? setStep("1") : () => {})}
        size={"lg"}
        variant="outline"
        mt={"1rem"}
        color={"var(--primary)"}
        borderColor={"var(--primary)"}
        _hover={{
          bg: "var(--primary)",
          color: "white",
        }}
      >
        VISIT GIFT SHOP
      </Button>
    </Grid>
  );
};

export default CartContainer;
