// TableBody.tsx
import React from "react";

// import styles from './body.module.scss'
import NameInfo from "components/nameinfo/NameInfo";
// import formatTimestamp from "utils/formatTimestamp";
// import formatLocalCurrency from "utils/formatLocalCurrency";
import moment from "moment";
import CurrencyConverter from "utils/CurrencyConverter";

type TableBodyProp = {
  name: string;
  description: string;
  amount: string;
  type?: string;
  date: string;
  image?: string;
  created_at?: string;
};

interface TableBodyProps {
  data?: TableBodyProp[];
}

const TableBody: React.FC<TableBodyProps> = ({ data }) => {
  return (
    <section className="grid pb-[1.5rem]  border-[var(--gray-light)] border-b-[1px]  ">
      {data?.map((item: any, index: number) => (
        <Tr item={item} key={index} />
      ))}
    </section>
  );
};

export default TableBody;

const data: Record<string, string> = {
  Airtime: "/Services/sv-1.svg",
  Data: "/Services/sv-2.svg",
  Internet: "/Services/sv-3.svg",
  Electricity: "/Services/sv-4.svg",
  "Cable TV": "/Services/sv-5.svg",
  Cable: "/Services/sv-5.svg",
  "Gift Card": "/Services/sv-6.svg",
  "Gift": "/Services/sv-6.svg",
  "Wallet Funding": "/Services/sv-7.svg",
  Education: "/Services/sv-7.svg",
  Insurance: "/Services/sv-8.svg",
  "Aitime To Cash": "/Services/sv-9.svg",
  Shopping: "/Services/sv-10.svg",
};

const Tr = ({
  item,
}: {
  item: {
    name: string;

    description: string;
    amount?: string;
    payment_type?: string;
    date: string;
    image?: string;
    created_at?: string;
    time?: string;
    transactions_amount?: string;
    transaction_type?: string;
    trasaction_type?: string;
    category?: string;
  };
}) => {
  return (
    <>
      <div
        className={
          " py-[1.25rem] pr-[1.25rem] xl:border-b-0 lg:border-b lg:border-b-[#eee]  border-b border-b-[#eee]  grid  lg:grid xl:flex md:flex justify-between items-center w-full md:gap-[2.5rem] gap-3 pb-[.93rem] md:border-[var(--gray-light)] md:border-b-[1px]"
        }
      >
        <div className={"w-full pb-3 md:pb-0  md:w-[45%] "}>
          <NameInfo
            title={(item?.category as string) || (item?.payment_type as string)}
            description={item.description}
            borderRadius="five"
            image={
              item?.category
                ? (data[item?.category as string] as string)
                : "/Services/sv-7.svg"
            }
          />
        </div>

        <div className=" flex  text-center flex-wrap lg:flex xl:grid  gap-4 md:grid grid-cols-[33%_33%_33%] items-center w-full">
          <h5
            className={`text-[var(--gray-text)] text-center md:before:content-none xl:before:content-none  text-[.875rem] font-[500] before:content-['Type:'] lg:before:content-['Type:'] before:text-[.875rem] before:font-bold before:mr-[.65rem] before:text-gray-900  `}
            data-th="Type"
          >
            {item.payment_type}
          </h5>
          <h5
            className={`${
              item?.transaction_type || item?.trasaction_type === "debit"
                ? "text-red"
                : "text-green"
            } md:before:content-none xl:before:content-none text-[.875rem] font-[600] before:content-['Amount:'] lg:before:content-['Amount:'] before:text-[.875rem] before:font-bold before:mr-[.65rem] before:text-gray-900 `}
            data-th="Amount"
          >{`${
            item?.transaction_type || item?.trasaction_type === "debit"
              ? "-"
              : ""
          }${
            item?.transactions_amount
              ? CurrencyConverter(item?.transactions_amount)
              : CurrencyConverter(item?.amount)
          }`}</h5>
          <h5
            className={`text-[var(--gray-text)]   md:before:content-none xl:before:content-none text-[.875rem] font-[500] text-right before:content-['Date:'] lg:before:content-['Date:'] before:text-[.875rem] before:font-bold before:mr-[.65rem] before:text-gray-900`}
            data-th="Date"
          >
            {item?.date
              ? `${moment(item?.date).format("MMMM Do YYYY")} ${item?.time}`
              : moment(item?.created_at).format("MMMM Do YYYY, h:mm:ss a")}
          </h5>
        </div>
      </div>
    </>
  );
};
