import { createSlice } from "@reduxjs/toolkit";


const AuthSlice = createSlice({
    initialState : {
        isAuthenticated : false,
        user : null,
        access: null ,
    },

    name : "auth",
    reducers : {
        login : (state, action) => {
            

            state.isAuthenticated = true;
            state.user = action.payload;
            state.access = action?.payload?.access_token
        },
        logout : (state) => {
            state.isAuthenticated = false;
            state.user = null;
            state.access =  null
        }
    }

})

export const { login, logout } = AuthSlice.actions;
export default AuthSlice.reducer;