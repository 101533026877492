import React from "react";
import NotificationsContainer from "container/Notifications/NotificationsContainer";
import OtherDashboard from "container/dashboard/OtherDashboard";

const NotificationsPage = () => {
  return (
    <OtherDashboard>
      <NotificationsContainer />
    </OtherDashboard>
  );
};

export default NotificationsPage;
