import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Box,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  useAddCardQuery,
  useThirdPartyAddCardMutation,
} from "features/Wallet/WalletApiSlice";
import ReactLoading from "react-loading";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const NewCardModal = ({ isOpen, onClose }: Props) => {
  const { data, isLoading } = useAddCardQuery(undefined);
  const [link, setLink] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);
  const [message, setMessage] = useState<string>("");
  const [channel, setChannel] = useState<string[]>(["card"]);

  useEffect(() => {
    if (data) {
      const _message = `To add a new card , ${data[0]?.description}`;
      const _amount = data[0]?.amount;
      const full_link = data[0]?.link;
      const split_link = full_link.split("api");
      const _link = split_link[1];
      setLink(_link);
      setAmount(_amount);
      setMessage(_message);
      setChannel(data[0]?.channel);
    }
  }, [data]);

  const [thirdPartyAddCard, { isLoading: isLoading3rdParty }] =
    useThirdPartyAddCardMutation();
  const { handleSubmit } = useForm();

  //     [
  // {
  //     provider: 'Add Card with PayStack Card',
  //     description: 'You will be charged a refundable fee of N100.',
  //     link: 'https://staging-nimblepay.jarapay.ng/api/v1/payment/paystack',
  //     amount: 10000,
  //     method: 'POST',
  //     channel: [ 'card' ],
  //     provider_image: 'https://nimblepay.jarapay.ng/paystack.png'
  //   }
  // ]
  const onSubmit = async (data: any) => {
    try {
      const response = await thirdPartyAddCard({
        link: link,
        body: {
          amount,
          channel,
        },
      }).unwrap();

     if(response){

        window.location.href = response.url;
     }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Debit Card</ModalHeader>
        <ModalCloseButton />

        <ModalBody py={"2rem"}>
          {isLoading ? (
            <div className="grid place-content-center place-items-center absolute right-[50%] top-0">
              {" "}
              <ReactLoading
                type={"spinningBubbles"}
                color={"var(--primary)"}
                height={50}
                width={50}
              />
            </div>
          ) : null}
          <Box as="form" onSubmit={handleSubmit(onSubmit)}>
            <Text
              px={{
                base: "1rem",
                lg: "2rem",
              }}
            >
              {message}
            </Text>
            <Flex
              justify={"space-between"}
              p={{
                base: "1rem",
                lg: "2rem",
              }}
            >
              <Button onClick={onClose} variant={"outline"} colorScheme="red">
                Cancel
              </Button>
              <Button
                bg="var(--primary)"
                colorScheme="messenger"
                isLoading={isLoading3rdParty}
                loadingText="Please wait"
                type="submit"
              >
                Continue
              </Button>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewCardModal;
