import React, { useEffect, useRef } from "react";
import { SVGS } from "constants/SVGS";
import {
  Flex,
  Text,
  Image,
  Button,
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  Heading,
  Center,
} from "@chakra-ui/react";
import formatLocalCurrency from "utils/formatLocalCurrency";
import { useGetTransactionByIdQuery } from "features/Transactions/TransactionApiSlice";
import Loading from "components/Loading/Loading";
import { toast } from "react-hot-toast";
import { css } from "@emotion/react";
import formatDateToDDMMYYYY from "utils/formatDDMMYYYY";
import { useNavigate } from "react-router-dom";
import CurrencyConverter from "utils/CurrencyConverter";
import { useReactToPrint } from "react-to-print";
import { toPng } from "html-to-image";

interface ItemInterface {
  item: {
    payment_type: string;
    category: string;
    amount: string;
    request_id: string;
    transactions_amount: string;
  };
}

interface Transaction {
  status: string;
  product_name: string;
  unique_element: string;
  unit_price: number;
  quantity: number;
  service_verification: any;
  channel: string;
  commission: number;
  total_amount: number;
  discount: any;
  type: string;
  email: string;
  phone: string;
  name: string | null;
  convinience_fee: number;
  amount: number;
  platform: string;
  method: string;
  transactionId: string;
}

interface TransactionResponse {
  transactions: Transaction;
  response_description: string;
  requestId: string;
  amount: string;
  transaction_date: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  purchased_code: string;
  certUrl: string;
}

interface Data {
  token: string;
}

const justify: string = "flex-end";
const color: string = "gray.700";
const dark: string = "gray.900";
const fontBig: string = "1.1rem";
const fontWeight: string = "700";

const ViewTransactions: React.FC<ItemInterface> = ({ item }) => {
  const { data, isLoading } = useGetTransactionByIdQuery(item?.request_id, {
    skip: !item?.request_id,
  });

  const navigate = useNavigate();
  const printRef = useRef(null);
  // console.log(data?.data)

  useEffect(() => {
    if (data?.data) {
      const __data = {
        "Number of Units": data?.data?.units,
        "Meter Number": data?.data?.content?.transactions?.unique_element,
        "Meter Name": data?.data?.customerName,
        Address: data?.data?.customerAddress,
        Units: data?.data?.units,
        // Debt: CurrencyConverter(data?.data?.amount),
        "Date of Purchase": formatDateToDDMMYYYY(
          data?.data?.transaction_date?.date
        ),
        "Customer Name": data?.data?.customerName,
        "Phone Number": data?.data?.customerPhone,
        "Cost of Electricity": CurrencyConverter(
          data?.data?.amount?.toString()
        ),

        // "Sub Total": data?.data?.amount,
        // "Convenience Fee": CurrencyConverter(
        //   data?.data?.content?.transactions?.convinience_fee
        // ),
        Total: CurrencyConverter(
          data?.data?.amount?.toString() +
            data?.data?.content?.transactions?.convinience_fee
        ),
      };

      localStorage.setItem("transactionDetails", JSON.stringify(__data));
      localStorage.setItem(
        "electricityToken",
        data?.data?.purchased_code?.split(" ")[2]
      );
      if (data?.data?.units) {
        navigate("/electricity-token");
      }
    }
  }, [data, navigate]);

  const transaction: TransactionResponse = data?.data?.content;
  const _data: Data = data?.data;

  const image = SVGS[item?.category];

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleDownloadPng = () => {
    if (printRef.current) {
      toPng(printRef.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "transaction.png";
          link.click();
        })
        .catch((err) => {
          console.error("Failed to download image", err);
          toast.error("Failed to download image");
        });
    }
  };

  return (
    <Box>
      <Flex
        className="text-gray.700 print:bg-white"
        align={"center"}
        justify={"center"}
        flexDir={"column"}
        ref={printRef}
        bg={"white"}
        py={"1rem"}
      >
        <Center
          flexDir={"column"}
          gap={".5rem"}
          padding=".8rem"
          boxShadow={"sm"}
          bg="gray.50"
          borderRadius={"lg"}
        >
          <Heading
            fontSize={{
              base: ".875rem",
              lg: "1rem",
            }}
            fontWeight={"400"}
          >
            Powered by
          </Heading>
          <Image src="/jarapay.svg" />
        </Center>
        <Box
          w={"100%"}
          h={".2rem"}
          bg={"gray.100"}
          borderRadius={"lg"}
          my={"2rem"}
        ></Box>
        {isLoading ? (
          <Loading />
        ) : (
          <TableContainer whiteSpace={"pre-wrap"}>
            <Table
              color={color}
              fontSize={"1rem"}
              w={{
                base: "100%",
              }}
              px={{
                base: "1rem",
                lg: "2rem",
              }}
              css={css`
                border-collapse: collapse;
              `}
            >
              <Tbody>
                <Tr>
                  <Td>
                    <Text
                      color={"gray.700"}
                      fontSize={{
                        base: "1rem",
                      }}
                    >{`${item.category} Amount`}</Text>
                  </Td>
                  <Td>
                    <Flex justify={justify}>
                      <Text
                        color={dark}
                        fontSize={fontBig}
                        fontWeight={fontWeight}
                      >
                        {formatLocalCurrency(
                          transaction?.transactions.amount
                            ? transaction?.transactions?.amount?.toString()
                            : item?.transactions_amount
                        )}
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
                <Detail
                  value={transaction?.transactions?.phone}
                  title="Phone Number"
                />
                <Detail
                  title="Transaction Fee"
                  value={formatLocalCurrency(
                    transaction?.transactions?.convinience_fee?.toString()
                  )}
                />
                <Detail
                  title="Transaction Reference"
                  value={transaction?.transactions?.transactionId}
                />
                <Detail title="Token" value={_data?.token} isToken={true} />
                <Detail
                  title="Total"
                  value={formatLocalCurrency(
                    item.amount || item?.transactions_amount
                  )}
                  isLast={true}
                />
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Flex>
      <Flex
        justify={"space-between"}
        mt={"2rem"}
        gap="1rem"
        px={{
          base: "1rem",
          lg: "2rem",
        }}
        align={"center"}
      >
        <Button
          colorScheme="messenger"
          padding={"1rem"}
          fontSize={"1rem"}
          color={"white"}
          bg={"var(--primary)"}
          onClick={handlePrint}
        >
          Print PDF
        </Button>
        <Button
          colorScheme="gray"
          padding={"1rem"}
          fontSize={"1rem"}
          // color={"white"}
          onClick={handleDownloadPng}
        >
          Download PNG
        </Button>
      </Flex>
    </Box>
  );
};

export default ViewTransactions;

interface DetailInterface {
  title: string;
  value: string | undefined | number;
  isLast?: boolean;
  isToken?: boolean;
}

const Detail: React.FC<DetailInterface> = ({
  value,
  title,
  isLast,
  isToken,
}) => {
  return value ? (
    <Tr>
      <Td>
        <Text fontWeight={isLast ? "700" : ""}>{title}</Text>
      </Td>
      <Td
        maxW={{ base: "20ch", lg: "100%" }}
        isNumeric={true}
        color={isToken ? "var(--primary)" : ""}
        fontWeight={fontWeight}
        fontSize={fontBig}
      >
        {value}
      </Td>
    </Tr>
  ) : null;
};
