import React, { useEffect } from "react";
import Heading from "./Heading/Heading";
import BackButton from "components/buttons/backbutton/BackButton";
import ProgressTracker from "components/ProgressTracker/ProgressTracker2";
import style from "./generalservicestyle.module.scss";
import PinForm from "./Form/EnterPin";
import ConfirmForm from "./Form/ConfirmForm";
import { setAmountToFund } from "features/Wallet/WalletSlice";
import ServicesPayment from "components/paymentOptions/ServicesPayment";
import DashboardContext from "context/Dashboard/DashboardContext";
import { useGetInternetProvidersQuery } from "features/Services/InternetService/InternetServiceApiSlice";
import { setInternetProviders } from "features/Services/InternetService/InternetServiceSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useForm } from "react-hook-form";
import InternetForm from "./Form/InternetForm";
import { setDataDetails } from "features/Services/DataService/DataSlice";
import { useVerifyInternetServiceMutation } from "features/Services/InternetService/InternetServiceApiSlice";
import LoadingSpinner from "components/Spinner/Spinner";
import { toast } from "react-hot-toast";
import formatLocalCurrency from "utils/formatLocalCurrency";
import { usePayInternetServiceMutation } from "features/Services/InternetService/InternetServiceApiSlice";
import { setSuccess, setPin } from "features/Services/ServicesSlice";
import useAddBeneficiary from "../../hooks/useAddBeneficiary";
import { data } from "container/Rewards/data";

const InternetService = () => {
  const [step, setStep] = React.useState<"1" | "2" | "3" | "4">("1");
  const [steps, setSteps] = React.useState<string[]>(["1"]);
  const { setCurrentPagename } = React.useContext(DashboardContext);
  const dispatch = useAppDispatch();
  const { setValue, control, handleSubmit } = useForm();
  const { formVariation } = useAppSelector(
    (state: any) => state.services as any
  );
  const [verifyInternetService, { isLoading }] =
    useVerifyInternetServiceMutation();
  const { internetProviders } = useAppSelector(
    (state: any) => state.internetService as any
  );
  const { dataDetails } = useAppSelector(
    (state: any) => state.dataService as any
  );
  const wallet = useAppSelector((state) => state.wallet as any);

  //pay mutation
  const [payInternetService, { isLoading: payLoading, isSuccess }] =
    usePayInternetServiceMutation();
  const { pin } = useAppSelector((state: any) => state.services as any);

  //add beneficiary
  const addBeneficiary = useAddBeneficiary();

  useEffect(() => {
    dispatch(setSuccess(isSuccess));
    dispatch(setPin([]));
  }, [isSuccess, dispatch]);

  const onSubmit = async (data: any) => {
    if (step === "1") {
      const serviceID = internetProviders?.find(
        (item: any) => item?.name === data?.provider?.label
      );

      try {
        const res = await verifyInternetService({
          serviceID: serviceID?.serviceID,
          billersCode: data?.email,
        }).unwrap();
        if (res?.data?.content?.error) {
          toast.error(res?.data?.content?.error);
        } else {
          dispatch(
            setDataDetails({
              ...serviceID,
              ...data,
              ...formVariation,
              accountName: res?.data?.content?.Customer_Name,
              accountId: res?.data?.content?.AccountList?.Account[0]?.AccountId,
              variation_amount: data?.bundle?.variation_amount,
            })
          );
          handleNext();
        }
      } catch (error: any) {
        console.log(error);
      }
    }
    if (step === "2") {
      handleNext();
    }

    if (step === "4") {
      console.log({
        serviceID: "smile-direct",
        name: "Smile Payment",
        minimium_amount: "100",
        maximum_amount: "150000",
        convinience_fee: "N0.00",
        product_type: "fix",
        image:
          "https://sandbox.vtpass.com/resources/products/200X200/Smile-Payment.jpg",
        provider: {
          serviceID: "smile-direct",
          name: "Smile Payment",
          minimium_amount: "100",
          maximum_amount: "150000",
          convinience_fee: "N0.00",
          product_type: "fix",
          image:
            "https://sandbox.vtpass.com/resources/products/200X200/Smile-Payment.jpg",
          value: "smile-direct",
          label: "Smile Payment",
        },
        bundle: {
          variation_code: "517",
          name: "SmileVoice ONLY 135 for 30days - 1,020 Naira",
          variation_amount: "1020.00",
          fixedPrice: "Yes",
          value: undefined,
          label: "SmileVoice ONLY 135 for 30days - 1,020 Naira",
        },
        email: "tester@sandbox.com",
        renewal: { value: "Off", label: "Off" },
        accountName: "THE TESTER ITSELF",
        accountId: 2348011111111,
      });
      const data: any = {
        serviceID: dataDetails?.serviceID,
        variation_code: dataDetails?.bundle?.variation_code,
        billersCode: dataDetails?.accountId,
        pin: pin?.join(""),
        variation_amount: dataDetails?.bundle?.variation_amount,
        slug_id: wallet.paymentSlug,
      };
      if (wallet.paymentSlug === 2 && wallet.signature) {
        data.signature = wallet.signature;
      }
      try {
        const res = await payInternetService(data).unwrap();
        if (res) {
          dispatch(setPin([]));
          dispatch(setSuccess(true));
          await addBeneficiary({
            service: res?.message,
            beneficiary_number: data.email,
            provider: "Internet",
          });
        }
      } catch (error: any) {
        if (error?.originalStatus === 429) {
          toast.error("Too many requests. Please try again later");
        }
        else if(error?.data?.error){
          toast.error(error?.data?.error);
        }
        toast.error("An error occured");
      }
    }
  };

  const { data } = useGetInternetProvidersQuery(undefined);

  useEffect(() => {
    if (data) {
      dispatch(setInternetProviders(data?.content));
    }
  }, [data, dispatch]);

  const handleNext = () => {
    setSteps([...steps, step]);

    if (step === "1") {
      setStep("2");
    }
    if (step === "2") {
      setStep("3");
    }
    if (step === "3") {
      setStep("4");
    }
  };

  const handleBack = () => {
    setSteps(steps.slice(0, -1));

    if (step === "1") {
      setStep("1");
    }

    if (step === "2") {
      setStep("1");
    }
    if (step === "3") {
      setStep("2");
    }
    if (step === "4") {
      setStep("3");
    }
  };

  let text = "All Services";
  if (step === "1") {
    setCurrentPagename("Buy Internet");
  }
  if (step === "2") {
    text = "Internet";
    setCurrentPagename("Confirm ");
  }
  if (step === "3") {
    text = "Confirm";
    setCurrentPagename("Payment Options");
  }
  if (step === "4") {
    setCurrentPagename("Transaction PIN");
    text = "Payment options";
  }

  return (
    <>
      {isLoading || payLoading ? <LoadingSpinner /> : null}
      <form onSubmit={handleSubmit(onSubmit)} className={style.data}>
        <div className="lg:pl-[2.5rem] pl-[1rem]" onClick={handleBack}>
          <BackButton
            text={text}
            link={step === "1" ? "/services" : "/services?category=electricity"}
          />
        </div>

        <section className={style.data__section}>
          {step === "1" ? (
            <Step1 steps={steps} control={control} setValue={setValue} />
          ) : null}

          {step === "2" ? <Step2 steps={steps} /> : null}

          {step === "3" ? (
            <Step3 steps={steps} handleNext={handleNext} />
          ) : null}

          {step === "4" ? <Step4 /> : null}

          <div className="grid place-content-center place-items-center">
            {step !== "4" && step !== "3" ? (
              <button type={"submit"} className="btn-primary">
                Continue
              </button>
            ) : null}
          </div>
        </section>
      </form>
    </>
  );
};

export default InternetService;

const Step1 = ({
  steps,
  control,
  setValue,
}: {
  steps: string[];
  control: any;
  setValue: any;
}) => {
  return (
    <div>
      <Heading
        title={"Pay for Internet"}
        desc="Pay for Internet bills safely, conveniently & easily."
        desc2="You can pay anytime and anywhere!"
      />
      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Internet Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>

      <InternetForm control={control} setValue={setValue} />
    </div>
  );
};

const Step2 = ({ steps }: { steps: string[] }) => {
  const [toggle, setToggle] = React.useState<boolean>(true);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  const { dataDetails } = useAppSelector(
    (state: any) => state.dataService as any
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setAmountToFund(
        formatLocalCurrency(dataDetails?.variation_amount as string)
      )
    );
  }, [dataDetails ,  dispatch]);

  const confirmData = [
    {
      title: "Account ID",
      value: dataDetails?.accountId,
    },
    {
      title: "Account Name",
      value: dataDetails?.accountName,
    },
    {
      title: "Bundle Amount",
      value: formatLocalCurrency(dataDetails?.bundle?.variation_amount),
    },
    {
      title: "Phone Number",
      value: dataDetails?.accountId,
    },
    {
      title: "Renewal Status",
      value: dataDetails?.renewal?.value,
    },
    {
      title: "Service Charge",
      value: "No",
    },

    {
      title: "Total",
      value: formatLocalCurrency(dataDetails?.bundle?.variation_amount),
    },
  ];

  return (
    <div>
      <Heading
        title={"Confirm Internet Purchase"}
        desc="Please confirm your internet purchase"
      />

      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Internet Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>
      <ConfirmForm
        handleSaveBeneficiary={handleToggle}
        saveBeneficiary={toggle}
        data={confirmData}
        src={dataDetails?.image}
      />
    </div>
  );
};

const Step3 = ({
  steps,
  handleNext,
}: {
  steps: string[];
  handleNext: () => void;
}) => {
  return (
    <div>
      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Internet Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>
      <ServicesPayment onClick={handleNext} />
    </div>
  );
};

const Step4 = () => {
  const confirmDetails = useAppSelector(
    (state) => state.dataService?.dataDetails as any
  );
  return (
    <div className="grid place-content-center place-items-center pt-[2.5rem]">
      <PinForm
        amount={`Pay ${formatLocalCurrency(confirmDetails?.variation_amount)}`}
        successMessage={{
          title: "Internet Purchase Successful",
          message: (
            <>
              {`Your have successfully purchased ${
                confirmDetails?.provider?.name?.split(" ")[0]
              } data of `}
              <span>
                {formatLocalCurrency(confirmDetails?.variation_amount)}
              </span>
            </>
          ),
          buttonText: "Dashboard",
          link: "/services",
        }}
      />
    </div>
  );
};
