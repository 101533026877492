import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import GetCookieValue from "utils/GetCookieValue";
// import { logout } from "features/Auth/AuthSlice";
// import Cookies from "js-cookie";

export const api = createApi({
  reducerPath: "unauth_user",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
  }),
  endpoints: (builder) => ({}),
  tagTypes: ["Auth", "Card"],
});

// Auth API

const BaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API,
  prepareHeaders: (headers, { getState }) => {
    const cookie = GetCookieValue("jara_access");
    headers.set("authorization", `Bearer ${cookie}`);
    return headers;
  },
});

const customFetchBase = async (args: any, api: any, extraOptions: any) => {
  let result = await BaseQuery(args, api, extraOptions);


  // console.log(navigator.connection)
  // if (
  //   //system / window is offline
  //   window.navigator.onLine === false
  // ) {
  //   //crear cookie
  //   Cookies.remove("jara_access");
  //   Cookies.remove("jara_refresh");
  //   Cookies.remove("jara_user");

  //   localStorage.clear();

  //   api.dispatch(logout());
  //   window.location.href = "/sign-in";
  // }
  return result;
};

export const authApi = createApi({
  reducerPath: "auth_user",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({}),
  tagTypes: [
    "Profile",
    "Auth",
    "Subscriptions",
    "Card",
    "Beneficiary",
    "Rewards",
    "PaymentOptions",
    "gift",
    "Transactions",
    "notification",
  ],
});
