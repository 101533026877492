import React, { useState, useEffect } from "react";
import Pin from "components/form/Auth/Pin/Pin";
import style from "./form.module.scss";
import Heading from "../Heading/Heading";
import LoginSuccessful from "components/successpage/LoginSuccessful";
import Modal from "components/successpage/Modal";
import { setPin } from "features/Services/ServicesSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";

interface PinFormProps {
  amount: string;
  successMessage?: {
    title: string;
    message: React.ReactNode;
    buttonText: string;
    link?: string;
    elect?: string;
  };
}

const PinForm: React.FC<PinFormProps> = ({ amount, successMessage }) => {
  const [pin, myPin] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setPin(pin));
  }, [dispatch, pin]);

  const { isSuccess } = useAppSelector((state: any) => state.services as any);

  return (
    <>
      <div className={style.pin__form}>
        <Heading
          title=" Enter Your PIN"
          desc={"Please enter your transaction PIN to confirm subscription."}
        />

        <Pin tokenNumber={4} title="" myPin={myPin} concentrate={true} />
        <button
          disabled={pin.join("")?.length < 4}
          type="submit"
          className="btn-primary 2xl:mt-[260px] xl:mt[200px] lg:mt-[150px] md:mt-[100px] mt-[50px] disabled:cursor-not-allowed"
        >
          {amount}
        </button>
      </div>
      {isSuccess ? (
        <Modal handleClose={() => {}} open={true}>
          <LoginSuccessful
            link={successMessage?.link}
            message={successMessage?.message}
            title={successMessage?.title as string}
            buttonText={successMessage?.buttonText as string}
            elect={successMessage?.elect as string}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default PinForm;
