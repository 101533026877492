// store.ts
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer";
import { api, authApi } from "app/ApiSlice";

//disable  dev tools


export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, authApi.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
