import React from "react";
import TransactionInfo from "components/nameinfo/TransactionInfo";
import style from "./transaction.module.scss";
// import Modal from 'components/successpage/Modal'
// import SuccessMessage from './SuccessMessage'
import formatLocalCurrency from "utils/formatLocalCurrency";
import ModalContainer from "components/Modals/ModalContainer";
import { useDisclosure } from "@chakra-ui/react";
import ViewTransactions from "./ViewTransactions";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface Prop {
  item: any;
  handleClick: () => void;
}
const Item: React.FC<Prop> = ({ item, handleClick = () => {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const handleOpen = (transaction_id: string) => {
    navigate(`/transactions?id=${transaction_id}`);
    onOpen();
  };

  const data: Record<string, string> = {
    Airtime: "/Services/sv-1.svg",
    Data: "/Services/sv-2.svg",
    Internet: "/Services/sv-3.svg",
    Electricity: "/Services/sv-4.svg",
    "Cable TV": "/Services/sv-5.svg",
    "Cable": "/Services/sv-5.svg",
    "Gift Card": "/Services/sv-6.svg",
    "Gift": "/Services/sv-6.svg",
    
    "Wallet Funding": "/Services/sv-7.svg",
    "Education": "/Services/sv-7.svg",
    Insurance: "/Services/sv-8.svg",
    "Aitime To Cash": "/Services/sv-9.svg",
    Shopping: "/Services/sv-10.svg",
  };

  return (
    <>
      <div
        className={`${style.list_item} cursor-pointer`}
        onClick={() => handleOpen(item?.request_id)}
      >
        <TransactionInfo
          borderRadius="five"
          title={item.category}
          description={item.company}
          image={data[item?.category as string] as string}
        />
        <p className={style.type}>{item.payment_type}</p>
        <p
          className={`${style.amount} ${
            item.transation_type === "debit" || item.trasaction_type === "debit"
              ? style.debit
              : ""
          }`}
        >
          {`${
            item.transation_type === "debit" || item.trasaction_type === "debit"
              ? "-"
              : ""
          }${
            item.amount
              ? formatLocalCurrency(item.amount)
              : formatLocalCurrency(item?.transactions_amount)
          }`}
        </p>
        <div className={style.date_and_time}>
          <p className={style.date}>
            {moment(item.date).format("MMMM Do YYYY")}
          </p>
          <p className={style.time}>{item.time}</p>
        </div>
        <p className={style.reference}>
          {item?.request_id
            ? item?.request_id
            : item?.transactions_mobile_number}
        </p>
        <button
          onClick={() => handleOpen(item?.request_id)}
          className={`btn-primary max-w-[71px] text-[14px] font-semibold transition  ${style.btnn} ${style.btn}`}
        >
          View
        </button>
      </div>

      <ModalContainer size="2xl" isOpen={isOpen} onClose={onClose}>
        <ViewTransactions item={item} />
      </ModalContainer>
    </>
  );
};

export default Item;
