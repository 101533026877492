import React , {useEffect} from 'react'
import style from './input.module.scss'
import { useController } from 'react-hook-form'
import NumberFormatter from 'utils/NumberFormatter';

const ProfileInput: React.FC<InputState> = ({
    name, label, type, control, placeholder, defaultValue, className , rules, maxLength
}) => {



    const { field, formState: {errors}} = useController({
        control,
        name,
        defaultValue,
        rules
    })

    // const hasContent = (): boolean => {
    //     return field.value && field.value !== ''
    //   };
    useEffect(()=> {
    if (name === 'bvn' && field.value) {
        field.onChange(NumberFormatter(field.value));
      }
    }, [field, name]);





    return (
        <section className={` relative ${style.input__container}`}>
            <label>
                {label}
            </label>
            <input
                className={`${style.input} ${defaultValue?.length ? style.default : ""} blur:border-primary focus:border-primary relative z-[999] ${style[className as string]}`}
                {...field}
                type={type}
                placeholder={placeholder}
                defaultValue={defaultValue}
                maxLength={maxLength}


            />
              <p className='text-red text-[.79rem]'>
                {errors && errors[name] ?  errors[name]?.message as string : ''}
            </p>
            {
                (type === 'date' || type === 'datetime-local')
                    && defaultValue?.length && !field.value.length  ?
                    <span className='absolute p-3 bottom-[.7rem] left-[2rem] text-[var(--text-1)] text-[1.4rem] font-[500] bg-[var(--gray-light)] block z-30 '>
                        {defaultValue}
                    </span> : null
            }


        </section>
    )
}

export default ProfileInput