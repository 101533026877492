import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box ,
} from "@chakra-ui/react";

import React from "react";

interface ModalContainerInterface {
  children: React.ReactNode;
  size: string;
  isOpen: boolean;
  onClose: () => void;
}

const ModalContainer: React.FC<ModalContainerInterface> = ({
  children,
  size,
  isOpen,
  onClose,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} size={size} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Box>
        <ModalCloseButton mt={'1rem'} mr={'1rem'} fontSize={'1rem'} color={'gray.500'}  />
        </Box>
        <ModalBody my={{
            base:'2rem' , '2xl':'3rem'
        }} padding={{
            base: '.625rem' , lg:'1rem'  , '2xl':'1.1rem'
        }}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalContainer;
