import React from "react";
import style from "./card.module.scss";
import { Grid, Text, Box, Image } from "@chakra-ui/react";
import CurrencyConverter from "utils/CurrencyConverter";
import { useAppDispatch } from "hooks/redux";
import { addProductToCart } from "features/Services/GiftCardService/GiftCardServiceSlice";
import toast from "react-hot-toast";

interface card {
  item: {
    image_path: string;
    name: string;
    price: string;
    available_quantity?: number;
  };
  handleClick: (item: {
    image_path: string;
    name: string;
    price: string;
  }) => void;
}

const Card: React.FC<card> = ({ item, handleClick }) => {
  const dispatch = useAppDispatch();

  const handleAddToCart = (e: any) => {
    if (item?.available_quantity === 0) {
      return;
    } else {
      e.stopPropagation();
      dispatch(addProductToCart({ ...item, quantity: 1 }));
      toast.success("Product added to cart");
    }
  };

  const handleCardClick = (e: any) => {
    e.stopPropagation();
    // if (item?.available_quantity === 0) {
    //   return;
    // } else {
    handleClick(item);
    // }
  };

  return (
    <Grid
      // opacity={item?.available_quantity === 0 ? ".5" : "1"}
      padding={".7rem"}
      className={style.card}
      onClick={handleCardClick}
      boxShadow={'sm'}
    >
      <Text
        textAlign={"left"}
        justifyContent={"left"}
        borderRadius={".8rem"}
        py={".1rem"}
        px={".5rem"}
        bg={
          item?.available_quantity !== 0
            ? "var(--light-green)"
            : "var(--gray-lighter-bg)"
        }
        fontSize={".75rem"}
        maxW={"max-content"}
        maxH={"1.5rem"}
        fontWeight={500}
        // color={'gray.300'}
      >
        {item?.available_quantity === 0 ? "Unavailable" : "Available"}
      </Text>
      {/* <div className={style.card__image__container}> */}
      <Image src={item.image_path} alt={item.name} />
      {/* </div> */}
      <Box py={"1rem"} className={style.card__content__container}>
        <Text as={"h3"} noOfLines={2}>
          {item.name}
        </Text>
        <h4 className="flex justify-between flex-wrap">
          <span>{CurrencyConverter(item.price)}</span>
          {item?.available_quantity !== 0 ? (
            <span
              className="font-bold text-[.875rem] text-[#3C4242]"
              onClick={handleAddToCart}
            >
              Add To Cart
            </span>
          ) : null}
        </h4>
      </Box>
    </Grid>
  );
};

export default Card;
