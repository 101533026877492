import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Grid,
  Text,
  Flex,
} from "@chakra-ui/react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useUploadImageMutation } from "features/Profile/ProfileApiSlice";
import { toast } from "react-hot-toast";

interface ImageUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ImageUploadModal: React.FC<ImageUploadModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  const [uploadImage, { isLoading }] = useUploadImageMutation();

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image_name", selectedFile);

      try {
        await uploadImage(formData).unwrap();
        toast.success("Image uploaded successfully");
        onClose();
      } catch (error: any) {
        toast.error(error.data.message);
      }
    }
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Image</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p={{
            base: "1rem",
            lg: "2rem",
          }}
        >
          <Grid placeContent={"center"} placeItems={"center"}>
            <FormControl>
              <FormLabel textAlign={"center"}>Choose an image</FormLabel>
              <Button
                onClick={handleClick}
                leftIcon={<Icon as={FaCloudUploadAlt} />}
                colorScheme="blue"
                bg={"var(--primary)"}
              >
                Select Image
              </Button>
              <Input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={handleFileChange}
                display="none"
              />
              {selectedFile && (
                <Text fontSize={".875rem"} color="gray.400">
                  {selectedFile.name}
                </Text>
              )}
            </FormControl>
          </Grid>
          <Flex mt={"2rem"} width="100%" justify={"space-between"} gap={"1rem"}>
            <Button onClick={onClose} colorScheme="red" variant={"outline"}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              color="primary"
              variant={"outline"}
              isDisabled={!selectedFile || isLoading}
              onClick={handleUpload}
              isLoading={isLoading}
              loadingText="Uploading..."
            >
              Save
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageUploadModal;
