import style from "./cashinfo.module.scss";

import { useState, useEffect, useRef } from "react";
import React from "react";
import maskString from "utils/maskString";

const CashInfo = ({
  name,
  value,
  Icon,
  show,
  toggleShow,
}: {
  name: string;
  value: string;
  Icon: any;
  show: boolean;
  toggleShow: () => void;
}) => {
  const [fontSize, setFontSize] = useState<number>(20); // Set an initial font size (in pixels)
  const textRef = useRef<HTMLParagraphElement | null>(null);

  const calculateMaxFontSize = () => {
    if (textRef.current) {
      const containerWidth = textRef.current.parentElement?.clientWidth || 0;
      const textWidth = textRef.current.scrollWidth;

      if (textWidth > containerWidth && textWidth > 20) {
        const characters = textRef.current.textContent?.length;
        const scaleFactor = containerWidth / textWidth;
        let newFontSize = fontSize * scaleFactor;

        if (characters && characters > 6) {
          newFontSize = Math.max(newFontSize, 12);
        }

        setFontSize(newFontSize);
      }
    }
  };

  useEffect(() => {
    calculateMaxFontSize();
    window.addEventListener("resize", calculateMaxFontSize);
    return () => {
      window.removeEventListener("resize", calculateMaxFontSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontSize]);

  const [xlRem, setXlRem] = useState<string>("2rem");

  useEffect(() => {
    if (value?.length > 10) {
      setXlRem("reduce__Xl");
    }
    if (value?.length > 12) {
      setXlRem("reduce__Lg");
    }
    if (value?.length > 15) {
      setXlRem("reduce__Md");
    }
  }, [value]);

  return (
    <article className={style.cashInfo__item}>
      <div className={style.cashInfo__item__icon}>
        <Icon />
      </div>
      <div className={style.cashInfo__item__info}>
        <h3>{name}</h3>
        <div className={style.cashInfo__item__info__value}>
          <p className={`${style.rem} ${style[xlRem]} transition-all`}>
            {!show ? maskString(value) : value}
          </p>
          {!show && name.includes("Wallet Balance") ? (
            <svg
              className="ml-[.3rem] w-[1.2rem] h-[1.2rem] cursor-pointer"
              onClick={toggleShow}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.53 9.46992L9.47001 14.5299C8.82001 13.8799 8.42001 12.9899 8.42001 11.9999C8.42001 10.0199 10.02 8.41992 12 8.41992C12.99 8.41992 13.88 8.81992 14.53 9.46992Z"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998C8.47 3.72998 5.18 5.80998 2.89 9.40998C1.99 10.82 1.99 13.19 2.89 14.6C3.68 15.84 4.6 16.91 5.6 17.77"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.42001 19.5299C9.56001 20.0099 10.77 20.2699 12 20.2699C15.53 20.2699 18.82 18.1899 21.11 14.5899C22.01 13.1799 22.01 10.8099 21.11 9.39993C20.78 8.87993 20.42 8.38993 20.05 7.92993"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.51 12.7C15.25 14.11 14.1 15.26 12.69 15.52"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.47 14.53L2 22"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 2L14.53 9.47"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : name.includes("Wallet Balance") ? (
            <svg
              className="ml-[.3rem] w-[1.2rem] h-[1.2rem] cursor-pointer"
              onClick={toggleShow}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="vuesax/linear/eye">
                <g id="eye">
                  <path
                    id="Vector"
                    d="M15.58 11.9999C15.58 13.9799 13.98 15.5799 12 15.5799C10.02 15.5799 8.42004 13.9799 8.42004 11.9999C8.42004 10.0199 10.02 8.41992 12 8.41992C13.98 8.41992 15.58 10.0199 15.58 11.9999Z"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.39997C18.82 5.79997 15.53 3.71997 12 3.71997C8.46997 3.71997 5.17997 5.79997 2.88997 9.39997C1.98997 10.81 1.98997 13.18 2.88997 14.59C5.17997 18.19 8.46997 20.27 12 20.27Z"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </g>
            </svg>
          ) : null}
        </div>
      </div>
    </article>
  );
};

export default CashInfo;
