import React from "react";
import Banner from "components/GiftCard/Banner/Banner";
import CardList from "components/GiftCard/Card/CardList";
import style from "./dashboard.module.scss";
import {
  useGetGiftCardProductsQuery,
  useSearchGiftCardProductsQuery,
} from "features/Services/GiftCardService/GiftCardApiSlice";
import { useAppSelector } from "hooks/redux";
//import LoadingScreen from 'components/LoadingScreen/LoadingScreen'
import { useAppDispatch } from "hooks/redux";
import {
  setProducts,
  setFullData,
} from "features/Services/GiftCardService/GiftCardServiceSlice";
import { useEffect } from "react";
import { Grid, Icon, Text, Heading } from "@chakra-ui/react";
import { BsDatabaseFillSlash } from "react-icons/bs";
import Spinner from "components/Spinner/Spinner";
import Loading from "components/Loading/Loading";


// empty icon

// const data: {
//     image: string
//     title: string
//     price: string
// }[] = [
//         {
//             title: "iTunes Gift Card - $100 | Email Delivery",
//             image: "/GiftCard/giftcard10$.svg",
//             price: "NGN 8,000"
//         },
//         {
//             title: "iTunes Gift Card - $100 | Email Delivery",
//             image: "/GiftCard/giftcard100$.svg",
//             price: "NGN 8,000"
//         },
//         {
//             title: "iTunes Gift Card - $100 | Email Delivery",
//             image: "/GiftCard/giftcard10$.svg",
//             price: "NGN 8,000"
//         },
//         {
//             title: "iTunes Gift Card - $100 | Email Delivery",
//             image: "/GiftCard/giftcard100$.svg",
//             price: "NGN 8,000"
//         },
//         {
//             title: "iTunes Gift Card - $100 | Email Delivery",
//             image: "/GiftCard/giftcard10$.svg",
//             price: "NGN 8,000"
//         },
//         {
//             title: "iTunes Gift Card - $100 | Email Delivery",
//             image: "/GiftCard/giftcard100$.svg",
//             price: "NGN 8,000"
//         },
//     ]

const GiftCardDashboard = ({
  handleClick,
}: {
  handleClick: (item: {
    image_path: string;
    name: string;
    price: string;
  }) => void;
}) => {




  const { page = 1, name } = useAppSelector(
    (state) => state.giftCardService as any
  );

  const {
    data: giftCard,
    isLoading,
    error,
  } = useGetGiftCardProductsQuery(page, {
    skip: name ,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const {
    data: giftCardByName,

    error: errorByName,
  } = useSearchGiftCardProductsQuery(name, {
    skip: !name,

  });

  // {
  //     status: 403,
  //     data: { error: 'This service is not available at this moment' }
  //   }


  const dispatch = useAppDispatch();

  useEffect(() => {
    if (giftCard || giftCardByName) {
      if (giftCardByName) {
        dispatch(setProducts(giftCardByName?.data));
        dispatch(setFullData(giftCardByName));
      } else if (giftCard) {
        dispatch(setProducts(giftCard?.data));
        dispatch(setFullData(giftCard));
      }
    }
  }, [dispatch, giftCard, giftCardByName, name]);

  useEffect(() => {
    if (!name) {
      dispatch(setProducts(giftCard?.data));
      dispatch(setFullData(giftCard));
    }
  }, [name, dispatch, giftCard]);

  return (
    <Grid py={'1rem'} as= "section" gap={{
      base: "2rem",
      md: "3rem",
    }} className={`relative ${style.dashboard}`}>
      <Banner />
      {(error || errorByName) && (
        <Grid
          placeItems={"center"}
          w={"full"}
          h={"30vh"}
          placeContent={"center"}
          gap={"1rem"}
          mt={"3rem"}
        >
          <Heading as={"h2"} size={"lg"} color={"gray.500"}>
            No Data
          </Heading>

          <Icon as={BsDatabaseFillSlash} boxSize={100} color={"gray.400"} />
          <Text color={"gray.500"}>
            This service is not available at this moment
          </Text>
        </Grid>
      )}

      <CardList
        handleClick={handleClick}
        data={giftCardByName?.data ? giftCardByName?.data : giftCard?.data}
        fullData={giftCardByName ? giftCardByName : giftCard}
      />
      {isLoading ? <Spinner /> : null}
    </Grid>
  );
};

export default GiftCardDashboard;
