const CurrencyConverter = (amount: number | null | undefined | string): string => {

    if (amount === null || amount === undefined || isNaN(Number(amount)) || amount === '') {
        return new Intl.NumberFormat('en-NG', {
            style: 'currency',
            currency: "NGN",

        }).format(0);
    }

    const formattedValue = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: "NGN",

    }).format(Number(amount));
    return formattedValue;
}

export default CurrencyConverter;
