import { authApi } from "app/ApiSlice";
import { logout } from "./AuthSlice";
import { resetStore } from "app/Store/actions";

const Logout = authApi.injectEndpoints({
  endpoints: (builder) => ({
    logout: builder.mutation({
      query: () => ({
        url: `/v1/auth/logout`,

        method: "GET",
      }),
      onQueryStarted(_arg, api) {
        // remove token from storage
        localStorage.removeItem("access");
        //clear cookies
        document.cookie =
          "jara_access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie =
          "refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        api.dispatch(resetStore());

        api.dispatch(logout());

        //clear store
      },
    }),
  }),
});

export const { useLogoutMutation } = Logout;
