import ChartHeader from './chartheader/ChartHeader';
import {CartesianGrid ,  LineChart, Line, XAxis, YAxis, Tooltip, ReferenceArea, ResponsiveContainer, ReferenceDot, ReferenceLine } from "recharts"
import React, { useState, useEffect } from 'react';
import { useAppSelector } from 'hooks/redux'
import { filterDatesByRange } from './Methods'

interface DataItem {
  date: string;
  income: number;
  expenses: number;
}


export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },

  },
};





export default function Chart() {
  //set Option for header selection
  const [option, setOption] = useState<string>("Last 7 days")
  const [allTransactions, setAllTransactions] = useState<any[]>()
  const [dates, setDates] = useState<string[]>([])
  const [incomeState, setIncome] = useState<number[]>([])
  const [expensesState, setExpense] = useState<number[]>([])
  const [dateRange, setDateRange] = useState<string[]>([])


  const { transactions, funding } = useAppSelector((state: any) => state.transaction)

  useEffect(() => {
    if (transactions?.length ||funding?.length) {


        const spreadArray = [...transactions, ...funding]
        //sort by date
        spreadArray.sort((a: any, b: any) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA.getTime() - dateB.getTime();
        });


        spreadArray.reverse()


        setAllTransactions(spreadArray)





    }

  }, [allTransactions?.length, funding, transactions])


  //SET INCOME AND EXPENSES
  useEffect(() => {
    let dates = filterDatesByRange(allTransactions, 7)
    // const data = ["Last 7 days", "Last 14 days", "Last 1 Month", "Last 3 Months"]
    //Verify options
    if (option === "Last 7 days") {
      dates = filterDatesByRange(allTransactions, 7)



    } else if (option === "Last 14 days") {
      dates = filterDatesByRange(allTransactions, 14)

    }
    else if(option === "This Month"){
      const date = new Date()
      //get current numbers of days spent this month
      const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      const daysLeft = daysInMonth - date.getDate()
      //get current numbers of days spent this month
        const daysSpent = daysInMonth - daysLeft




        dates = filterDatesByRange(allTransactions,daysSpent )

    }
    else if (option === "Last 1 Month") {
      dates = filterDatesByRange(allTransactions, 30)

    }
    else if (option === "Last 3 Months") {
      dates = filterDatesByRange(allTransactions, 90)
    }
    else if (option === "Last 6 Months") {
       dates = filterDatesByRange(allTransactions, 180)

    }
    else if (option === "Last 1 Year") {
        dates =  filterDatesByRange(allTransactions, 365)


    }


    setDates(dates)
    let dt = dates?.map((item: any) => {
      const dateFormatter = new Intl.DateTimeFormat("en-NG", {
        month: "short",
        day: "numeric"
      })

      return dateFormatter.format(new Date(item.date))


    })
    setDateRange(dt)

    // eslint-disable-next-line array-callback-return
    const exp = dates?.map((item: any) => {
      if (item.trasaction_type === 'debit' || item.transaction_type === 'debit') {
        return item.amount ? parseFloat(item.amount) : parseFloat(item.transactions_amount)
      }
    })


    // eslint-disable-next-line array-callback-return
    const inc = dates?.map((item: any) => {
      if (item.transaction_type === 'credit' || item.trasaction_type === 'credit') {
       return item.amount ? parseFloat(item.amount) : parseFloat(item.transactions_amount)
      }
    }

    )


    // const inc = dates?.map((item: any) => {

    setIncome(inc)
    setExpense(exp)











  }, [allTransactions, option])






  const formatYAxis = (value: number): string => {
    if (value >= 1000) {
      const formatter = new Intl.NumberFormat('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
      });
      return formatter.format(Number(value));
    } else {
      return value.toString();
    }
  }



  const generateData = (): DataItem[] => {
    const data: DataItem[] = [];
    const startDate = new Date();
// incomeState?.length < 7 ? incomeState?.concat(Array(7 - incomeState?.length).fill(0)) :
// expensesState?.length < 7 ? expensesState?.concat(Array(7 - expensesState?.length).fill(0)) :
// dates?.length < 7 ?  dateRange?.concat(Array(7 - dateRange?.length).fill(startDate?.toDateString())) :
    const income: number[] =  incomeState;
    const expenses: number[] =  expensesState;
    const date :string[] =  dateRange



    // if(income?.length >=7 && expenses?.length >=7){

       for (let i = 0; i <= Math.max(income?.length  , expenses?.length ); i++) {
        // const date = new Date(startDate);
        // date.setDate(startDate.getDate() + i);

        // Random value between 0 and 10000
        data.push({
          date: date[i],
          income: income[i] === undefined  || income[i] === null || isNaN(income[i])  ? 0: income[i] ,
          expenses: expenses[i] === undefined || expenses === null || isNaN(expenses[i])? 0 : expenses[i]
        });
      }

    // }

    // for (let i = 0; i <= 6; i++) {
    //   const date = new Date(startDate);
    //   date.setDate(startDate.getDate() + i);

    //   // Random value between 0 and 10000
    //   data.push({
    //     date: dateFormatter.format(date),
    //     income: income[i],
    //     expenses: expenses[i]
    //   });
    // }
    return data;
  };



  const data = generateData();





  // Calculate the highest income and expenses points
  const maxIncome = Math.max(...data.map((item) => item.income));
  const maxExpenses = Math.max(...data.map((item) => item.expenses));
  // Find the maximum value among both income and expenses

  const maxOverall = Math.max(maxIncome, maxExpenses);
  //max overall date
  const maxOverallDate = data.find((item) => item.income === maxOverall)?.date
  //max overall date


  return (
    <section className="relative max-w-[100vw] overflow-hidden w-full h-full border  rounded-[1rem] border-[#F5F5F5] py-[.9rem] px-[1.53rem] grid gap-5" >
      <ChartHeader setOption={setOption} title='Purchases' />

      <ResponsiveContainer width="100%" height={400}>
  <LineChart data={data}>
    <XAxis fontSize={'12px'}   dataKey="date" label={{ value: 'Date', position: 'insideBottom' }} />
    <YAxis fontSize={'12px'} label={{ value: 'Amount', angle: -90, position: 'insideLeft'  , }} />
    <CartesianGrid strokeDasharray="3 3" />
    <Tooltip />
    <Line dataKey="expenses" stroke="#246BFB" strokeWidth={3} dot={{ strokeWidth: 4, fill: '#246BFB' }} />
    <Line dataKey="income" stroke="#29A023" strokeWidth={3} dot={{ strokeWidth: 4, fill: '#29A023' }} />
    <ReferenceArea x1={0} x2={6} y1={maxOverall} y2={maxOverall} stroke='#FF0000' fill='#FF0000' />
    <ReferenceLine x={maxOverallDate} stroke='#F6FAFD' strokeWidth={40} />
    <ReferenceDot x={maxOverallDate} y={maxOverall} r={7} fill='#5243AA' />
  </LineChart>
</ResponsiveContainer>


    </section>

  )




}
