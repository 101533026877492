import React, { useContext, useEffect } from "react";

import DashboardContext from "context/Dashboard/DashboardContext";
import {
  useNotificationQuery,
  useUpdateNotificationMutation,
} from "features/Profile/ProfileApiSlice";
import NotificationSwitch from "components/link/NotificationSwitch";
import Loader from "components/Spinner/Spinner";
import { toast } from "react-hot-toast";

const NotificationsContainer = () => {
  const {
    setCurrentPagename,
    setBgContentColor,
    setBgSideMenuColor,
    setBgTopMenuColor,
  } = useContext(DashboardContext);

  useEffect(() => {
    setCurrentPagename("Notifications");
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgContentColor ? setBgContentColor("var(--gray-light)") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgTopMenuColor ? setBgTopMenuColor("var(--gray-light)") : null;
  }, [
    setBgContentColor,
    setBgSideMenuColor,
    setCurrentPagename,
    setBgTopMenuColor,
  ]);

  const { data, isLoading } = useNotificationQuery(undefined);
  const [updateNotification, { isLoading: isLoadingUpdate }] =
    useUpdateNotificationMutation();

  const updateNotificationCall = async (data: any) => {
    try {
      const res = await updateNotification(data).unwrap();
      console.log(res);
    } catch (error: any) {
      toast.error(error?.data?.message || "Something went wrong");
      console.log(error);
    }
  };

  const handleLogin = async () => {
    await updateNotificationCall({
      login_mail:
        data?.message?.login_mail?.toLowerCase() === "active"
          ? "inactive"
          : "active",
      transaction_mail: data?.message?.transaction_mail,
    });
  };
  const handleTransaction = async () => {
    await updateNotificationCall({
      transaction_mail:
        data?.message?.transaction_mail?.toLowerCase() === "active" ? "inactive" : "active",
      login_mail: data?.message?.login_mail,
    });
  };

  return (
    <main className="p-5 md:px-[4.75rem] py-[3.375rem] ">
      {isLoading || isLoadingUpdate ? <Loader /> : null}

      <section className="grid gap-2 2xl:gap-[2.188rem] xl:gap-[1.563rem] lg:gap-[1.25rem]">
        <NotificationSwitch
          title="Transactions"
          handleClick={handleTransaction}
          isActive={
            data?.message?.transaction_mail?.toLowerCase() === "active"
              ? true
              : false
          }
        />
        <NotificationSwitch
          title="User Login"
          handleClick={handleLogin}
          isActive={
            data?.message?.login_mail?.toLowerCase() === "active" ? true : false
          }
        />
      </section>
    </main>
  );
};

export default NotificationsContainer;
