import React, { useState } from "react";
import style from "./transactionpin.module.scss";
import Pin from "components/form/Auth/Pin/Pin";
import { useCreateTransactionPinMutation } from "features/Auth/AuthApiSlice";
import Loader from "components/Spinner/Spinner";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const SetNewPin = ({ onClick }: { onClick: () => void }) => {
  const [pin1, setPin1] = useState<any>([]);
  const [pin2, setPin2] = useState<any>([]);
  const id = sessionStorage.getItem("my_id");
  const navigate = useNavigate();

  const [createTransactionPin, { isLoading }] =
    useCreateTransactionPinMutation();

  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append("pin", pin1.join(",").replaceAll(",", ""));
    formData.append("confirm_pin", pin2.join(", ").replaceAll(", ", ""));

    formData.append("id", id as string);
    formData.append("device_id", "web");

    try {
      const res = await createTransactionPin(formData).unwrap();
      toast.success(res.message);
      onClick();
      navigate("/dashboard");
    } catch (error: any) {
      toast.error(error?.error as any);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`${style.small__mobile} md:pt-[3.9375rem] md:pl-[8.4375rem] lg:pl-[4.0625rem] 2xl:pl-[8.4375rem] p-[2.1875rem] grid place-content-center place-items-center`}
    >
      <div className=" mb-[4.6875rem] grid place-items-center ">
        <h1 className="text-dark text-[1.875rem] font-bold mb-[0.5rem]">
          Create Transaction PIN
        </h1>
        <h6 className="font-[400] text-[var(--dark-gray)] text-[1rem] ">
          Create your Pin to make transactions more secure
        </h6>
      </div>

      <div className="flex flex-col gap-[0.875rem]  md:mb-[8.125rem] lg:mb-[5.375rem] mb-[4.0625rem] relative">
        <Pin title="Enter Pin" tokenNumber={4} myPin={setPin1} />
        <Pin title="Confirm PIN" tokenNumber={4} myPin={setPin2} />
      </div>
      {
        // check if pin  match
        pin1?.join(",") !== pin2?.join(",") && pin1?.length > 5 ? (
          <Text fontSize={"12px"} color={"red.500"}></Text>
        ) : null
      }

      <button
        disabled={pin1.length !== 4 || pin2.length !== 4}
        type="submit"
        className="btn-primary transition-all "
      >
        Continue
      </button>
      {isLoading ? <Loader /> : null}
    </form>
  );
};

export default SetNewPin;
