import React  from "react";



import { useController } from "react-hook-form";

import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Grid,
  Text,
  Icon,
  Stack,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
//import { useEffect } from "react";
import { BsEnvelope } from "react-icons/bs";

const EmailInput = ({
  control,
  defaultValue,
  name,
  label,
  placeholder,
  setValue,
  open,
  disabled
}: {
  control: any;
  defaultValue: any;
  name: string;
  label: string;
  placeholder?: string;
  setValue?: (value: any, name: string) => void;
  open?: boolean
  disabled?:boolean
}) => {

  const { field } = useController({
    control,
    name,
    defaultValue
  });

  return (
    <FormControl>
      <Grid>
        <FormLabel>
          <Text
            color={"var(--dark-text"}
            fontWeight={"500"}
            fontSize={".875rem"}
          >
            {label}
          </Text>
        </FormLabel>
        <Stack spacing={'10'} >
          <InputGroup>
            <InputLeftElement >
            <Icon as={BsEnvelope} color={"var(--text-1)"} fontSize={'1.2rem'} />
            </InputLeftElement>

            <Input
              placeholder={placeholder}
              {...field}
              _placeholder={{
                color: "var(--text-1)",
                fontWeight: "500",
              }}
              color = {open? 'var(--dark-text)': 'var(--text-1)'}
              fontSize={".875rem"}
              borderRadius={"0.625rem"}
              fontWeight={"500"}

            //   padding={"1.25rem 25px 1.25rem 25px"}
              focusBorderColor="var(--primary)"
              bg={'inherit'}
              isDisabled={disabled}
            />
          </InputGroup>
        </Stack>
      </Grid>
    </FormControl>
  );
};

export default EmailInput;
