import React from 'react'
import style from './cartbottom.module.scss'
import FundingSummary from './FundingSummary/FundingSummary'
import ApplyBox from 'components/form/ApplyBox/ApplyBox'
import { Link } from 'react-router-dom'
import {Box , Flex} from '@chakra-ui/react'


interface Props {
    onClick : () => void
    setStep?: (step: "1") => void
  }

const CartBottom:React.FC<Props> = ({
    onClick , setStep
}) => {


    return (
        <Flex gap={'1rem'} flexDir={{
            base:'column' , lg:'row'
        }}  className={style.cart__bottom}>
            <div className={style.heading}>
                <h3 className={style.cart__bottom__title}>Discount  Codes</h3>
                <p>
                Enter your coupon code if you have one
                </p>
                <div className='mb-[3.7rem]'>
                <ApplyBox onClick={()=>{}} btnText='Apply Coupon' />
                </div>

                <Link to={'/gift-cards'}>
                <button className={style.white__button }>
                Continue Shopping
                </button>
                </Link>



            </div>

            <Box w={{
                base:'100%' , lg:'380px'
            }}>
                <FundingSummary onClick={onClick} title={'Order Summary'} buttonText={'Procced To Pay'} />
            </Box>
        </Flex>
    )
}

export default CartBottom