'use client'

import React from 'react';

import style from './input.module.scss';



const Input = ({
    label,
    name,
    placeholder,
    onChange,
    value,
    type,
}: {
    label: string,
    name: string,
    placeholder: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    value: string,
    type: string,
}) => {
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.currentTarget.style.border = '1px solid var(--primary)';
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        e.currentTarget.style.border = '1px solid var(--primary)';
    };









    return (
        <section className={style.input_container}>
            <label className={style.label} htmlFor={name}>
                {label}
            </label>
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`${style.input} outline-none border border-[var(--gray-text)] active:border-[var(--primary)]  `}

            />
        </section>
    );
};

export default Input;
