import React from 'react'
import style from './transaction.module.scss'


interface  Props {
    data : string[]
    reward?: string
}
const Header: React.FC<Props> = ({
    data , reward
}) => {
  return (
    <div className={`${style.header} ${style[reward as string] }`}>
       {
        data.map((item, index) => {
            return <h1 key={index}>{item}</h1>
        })
       }
    </div>
  )
}

export default Header