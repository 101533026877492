import React, { useEffect } from "react";
import Card from "./Card";
import style from "./cardlist.module.scss";

import { useAppDispatch } from "hooks/redux";
import {
  setPage,
  setProducts,
} from "features/Services/GiftCardService/GiftCardServiceSlice";

interface CardListProp {
  data: {
    image_path: string;
    name: string;
    price: string;
  }[];
  fullData?: any;
  handleClick: (item: {
    image_path: string;
    name: string;
    price: string;
  }) => void;
}

const ShortList: React.FC<CardListProp> = ({ handleClick, data }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {

    return () => {
      // dispatch(setProducts([]));
      dispatch(setPage(1));
    };

  }, []);

  return (
    <>
      <main className={style.cardlist}>
        {data?.map(
          (
            item: { image_path: string; name: string; price: string },
            index: number
          ) => {
            return <Card key={index} handleClick={handleClick} item={item} />;
          }
        )}
      </main>
    </>
  );
};

export default ShortList;
