'use client'

import React, { useEffect } from 'react';
import { useController } from "react-hook-form";
import style from './servicesinput.module.scss';
import NumberFormatter from "utils/NumberFormatter";
import formatPrice from 'utils/FormatCurrency';


const ServicesInput = ({
    label,
    name,
    placeholder,
    onChange,
    value,
    type,
    defaultValue,
    maxLength,
    rules,
    control,
    setValue,
    jamb

}: {
    label: string,
    placeholder: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    value?: string,
    defaultValue?: string
    type?: string;
    control: any
    name: string
    rules?: any
    maxLength?: number
    setValue?  : any
    jamb?: boolean
}) => {
    const { field, formState: { errors } } = useController({
        control,
        name,
        rules

    })
    useEffect(() => {
        if ((name === 'billersCode' && field.value && !jamb) || ((name === 'variation_amount') && field.value) || (name === 'meter_number' && field.value)) {

            field.onChange(NumberFormatter(field.value));
        }
    }, [field, name]);


    useEffect(() => {
        if (((name === 'variation_amount' || name === 'amount') && field.value)) {

            field.onChange(formatPrice(field.value));
        }
    }, [field, name]);




    useEffect(() => {
        if (defaultValue && setValue) {

            setValue(name, defaultValue)
        }
    }, [defaultValue, name, setValue])






    return (
        <section className={style.input__container}>
            <label className={style.label} htmlFor={name}>
                {label}
            </label>
            <input

                type={type}
                placeholder={placeholder}
                maxLength={maxLength}
                {...field}
                className={`${style.input__input} outline-none border w-full
                 active:border-[var(--primary)] focus:border-[var(--primary)] ${field.value ? "border-[var(--primary)]" : 'border-[var(--gray-text)]'} `}

            />
            <p className='text-red text-[.7rem]'>
                {errors && errors[name] ? errors[name]?.message as string : ''}
            </p>
        </section>
    );
};

export default ServicesInput;
