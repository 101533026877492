import React, { FC } from "react";
import style from "./creditcard.module.scss";
//import formatCreditCardNumber from 'utils/formatCardNumber';
import Draggable from "react-draggable";
import {
  useDeleteCardMutation,
  useSetDefaultCardMutation,
} from "features/Wallet/WalletApiSlice";
import { toast } from "react-hot-toast";
import DeleteDialog from "./DeleteDialog";
import { Flex, Text, useDisclosure } from "@chakra-ui/react";

interface CardProps {
  account: string;
  name: string;
  expiry_date: string;
  onClick: (account: string) => void;
  value: string;
  signature?: string;
  default_card?: string;
}

const Card: FC<CardProps> = ({
  account,
  name,
  expiry_date,
  onClick,
  value,
  signature,
  default_card,
}) => {
  const [deleteCard, { isLoading }] = useDeleteCardMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [setDefaultCard] = useSetDefaultCardMutation();

  const handleSetDefault = async () => {
    onClick(signature as string);
    if (default_card === "1") {
      return;
    }
      try {
        await setDefaultCard({ signature }).unwrap();
      } catch (error) {
        console.log(error);
      }

  };




  const handleStop = (e: any, data: any) => {
    const threshold = 100;

    if (Math.abs(data.x) > threshold) {
      onOpen();
    }
  };

  const handleClose = () => {
    onClose();
  };




  const handleDelete = async () => {
    try {
      await deleteCard({ signature }).unwrap();
      toast.success("Debit Card Deleted Successfully");
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error("Error Deleting Debit Card");
      handleClose();
    }
  };

  return (
    <Draggable onStop={handleStop} axis="x">
      <div
        className={`${style.card} ${style.my__card}`}
        onClick={handleSetDefault}
      >
        <DeleteDialog
          onClick={handleDelete}
          isLoading={isLoading}
          onClose={handleClose}
          isOpen={isOpen}
        />

        {default_card === "1" || value === signature ? (
          <svg
            className="absolute right-4 top-3"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10"
              cy="10"
              r="9"
              fill="#39CE8A"
              stroke="#FBFBFD"
              strokeWidth="2"
            />
            <path
              d="M14.2344 8.22347L9.42252 13.0354C9.25031 13.2076 9.02744 13.2887 8.79444 13.2887C8.57157 13.2887 8.34871 13.2076 8.17649 13.0354L5.76547 10.6345C5.42103 10.2799 5.42103 9.72277 5.76547 9.37833C6.1099 9.0339 6.6772 9.0339 7.02163 9.37833L8.79444 11.1511L12.9783 6.96731C13.3227 6.62288 13.89 6.62288 14.2344 6.96731C14.5789 7.32187 14.5789 7.87904 14.2344 8.22347Z"
              fill="white"
            />
          </svg>
        ) : null}

        <h1>{account}</h1>
        <Flex align={'start !important'} className={style.bottom}>
          <p>
            <span className={style.title}>Card Holder Name</span>
            <span className={style.name}>{name}</span>
          </p>
          <Text w={'12ch'}>
            <span className={style.title}>Expiry Date</span>
            <span className={style.expiry_date}>{expiry_date}</span>
          </Text>
        </Flex>
      </div>
    </Draggable>
  );
};

export default Card;
