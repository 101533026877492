import React, { useContext, useEffect } from "react";
import Heading from "./Heading/Heading";
import BackButton from "components/buttons/backbutton/BackButton";
import ProgressTracker from "components/ProgressTracker/ProgressTracker2";
import style from "./generalservicestyle.module.scss";
import EducationForm from "./Form/EducationForm";
import PinForm from "./Form/EnterPin";
import ConfirmForm from "./Form/ConfirmForm";

import ServicesPayment from "components/paymentOptions/ServicesPayment";
import DashboardContext from "context/Dashboard/DashboardContext";

import { useGetEducationProviderQuery } from "features/Services/EducationService/EducationApiSlice";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { useBuyEducationMutation } from "features/Services/EducationService/EducationApiSlice";
import LoadingSpinner from "components/Spinner/Spinner";

import formatLocalCurrency from "utils/formatLocalCurrency";
import { setAmountToFund } from "features/Wallet/WalletSlice";
import { toast } from "react-hot-toast";
import { setSuccess } from "features/Services/ServicesSlice";
import { setPin } from "features/Services/ServicesSlice";
import {
  setEducationDetails,
  setEducationProviders,
} from "features/Services/EducationService/EducationSlice";

import useAddBeneficiary from "hooks/useAddBeneficiary";

const EducationService = () => {
  const [step, setStep] = React.useState<"1" | "2" | "3" | "4">("1");
  const [steps, setSteps] = React.useState<string[]>(["1"]);
  const { setCurrentPagename } = useContext(DashboardContext);
  const dispatch = useDispatch();
  const { data: educationProvider } = useGetEducationProviderQuery(undefined);
  const { educationProviders, educationDetails } = useSelector(
    (state: any) => state.educationService as any
  );
  const { formVariation, pin } = useSelector(
    (state: any) => state.services as any
  );
  const wallet = useSelector((state: any) => state.wallet as any);

  //Buy Education TV
  const [buyEducation, { isLoading: isLoadingBuy }] = useBuyEducationMutation();

  //add beneficiary
  const addBeneficiary = useAddBeneficiary();

  useEffect(() => {
    if (educationProvider) {
      dispatch(setEducationProviders(educationProvider?.content));
    }
  });

  const { control, handleSubmit, setValue } = useForm();

  const handleNext = () => {
    setSteps([...steps, step]);

    if (step === "1") {
      setStep("2");
    }
    if (step === "2") {
      setStep("3");
    }
    if (step === "3") {
      setStep("4");
    }
  };

  const handleBack = () => {
    setSteps(steps.slice(0, -1));

    if (step === "1") {
      setStep("1");
    }

    if (step === "2") {
      setStep("1");
    }
    if (step === "3") {
      setStep("2");
    }
    if (step === "4") {
      setStep("3");
    }
  };

  if (step === "1") {
    setCurrentPagename("Buy Exam PIN");
  }
  let text = "All Services";
  if (step === "2") {
    text = "Education";
    setCurrentPagename("Confirm");
  }
  if (step === "3") {
    text = "Confirm";
    setCurrentPagename("Payment Options");
  }
  if (step === "4") {
    text = "Payment options";
    setCurrentPagename("Transaction PIN");
  }

  const onSubmit = async (data: any) => {
    if (step === "1") {
      dispatch(setSuccess(false));

      //Search Id
      const serviceID = educationProviders.find(
        (item: any) => item.name === data.serviceID?.name
      )?.serviceID;
      const image = educationProviders.find(
        (item: any) => item.name === data.serviceID?.name
      )?.image;

      dispatch(
        setEducationDetails({ ...data, ...formVariation, serviceID, image })
      );

      //set to next
      handleNext();
    }
    if (step === "2") {
      handleNext();
    }
    if (step === "4") {
      const data: any = {
        billersCode: educationDetails?.billersCode,
        serviceID: educationDetails?.serviceID,
        variation_code: educationDetails?.variation_code,
        phone: educationDetails?.phone_number,
        variation_amount: parseFloat(educationDetails?.variation_amount),
        quantity: educationDetails?.quantity,
        pin: pin?.join(""),
        slug_id: wallet.paymentSlug,
      };

      if (wallet.paymentSlug === 2 && wallet.signature) {
        data.signature = wallet.signature;
      }
      try {
        const res = await buyEducation(data).unwrap();
        if (!res.error) {
          dispatch(setPin([]));
          dispatch(setSuccess(true));
          await addBeneficiary({
            service: res?.message,
            beneficiary_number: educationDetails?.phone_number,
            provider: "Education",
          });
        } else {
          toast.error(res?.error);
        }
      } catch (error: any) {
        if (error?.data?.error) {
          toast.error(error?.data?.error);
        }
        else  if (error?.originalStatus === 429) {
          toast.error("Too many requests. Please try again later");
        }

        else {
          toast.error("An error occured while processing your request");
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.data}>
      {isLoadingBuy ? <LoadingSpinner /> : null}
      <div className="lg:pl-[2.5rem] pl-[1rem]" onClick={handleBack}>
        <BackButton
          text={text}
          link={step === "1" ? "/services" : "/services?category=education"}
        />
      </div>

      <section className={style.data__section}>
        {step === "1" ? (
          <Step1 steps={steps} control={control} setValue={setValue} />
        ) : null}

        {step === "2" ? <Step2 steps={steps} /> : null}

        {step === "3" ? <Step3 steps={steps} handleNext={handleNext} /> : null}

        {step === "4" ? <Step4 /> : null}

        <div className="grid place-content-center place-items-center">
          {step !== "4" && step !== "3" ? (
            <button type="submit" className="btn-primary">
              Continue
            </button>
          ) : null}
        </div>
      </section>
    </form>
  );
};

export default EducationService;

const Step1 = ({
  steps,
  control,
  setValue,
}: {
  steps: string[];
  control: any;
  setValue: any;
}) => {
  return (
    <div>
      <Heading
        title={"Pay for Exam PIN"}
        desc="Pay for Exam PINs safely, conveniently & easily."
        desc2="You can pay anytime and anywhere!"
      />
      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Education Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>

      <EducationForm control={control} setValue={setValue} />
    </div>
  );
};

const Step2 = ({ steps }: { steps: string[] }) => {
  //get eduction details
  const { educationDetails } = useSelector(
    (state: any) => state.educationService as any
  );
  const subTotal =
    parseFloat(educationDetails?.variation_amount) *
    parseFloat(educationDetails?.quantity);
  const total = subTotal + parseFloat(educationDetails?.convenience_fee);

  const dispatch = useDispatch();
  //get education providers

  useEffect(() => {
    dispatch(
      setAmountToFund(
        educationDetails?.service_charge
          ? formatLocalCurrency(total?.toString())
          : formatLocalCurrency(subTotal?.toString())
      )
    );
  }, [dispatch, educationDetails?.service_charge, subTotal, total]);

  const confirmData = [
    {
      title: "Product",
      value: educationDetails?.name,
    },
    {
      title: "Unit Price",
      value: formatLocalCurrency(educationDetails?.variation_amount),
    },
    {
      title: "Quantity",
      value: educationDetails?.quantity,
    },
    {
      title: "Sub Total",
      value: formatLocalCurrency(subTotal?.toString()),
    },
    {
      title: "Convenience Fee",
      value: formatLocalCurrency(educationDetails?.service_charge),
    },

    {
      title: "Total",
      value: educationDetails?.service_charge
        ? formatLocalCurrency(total?.toString())
        : formatLocalCurrency(subTotal?.toString()),
    },
  ];

  const [toggle, setToggle] = React.useState<boolean>(true);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <div>
      <Heading
        title={"Confirm Exam PIN Purchase"}
        desc="Please confirm your PIN purchase"
      />

      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Education Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>
      <ConfirmForm
        handleSaveBeneficiary={handleToggle}
        saveBeneficiary={toggle}
        data={confirmData}
        src={educationDetails?.image}
      />
    </div>
  );
};

const Step3 = ({
  steps,
  handleNext,
}: {
  steps: string[];
  handleNext: () => void;
}) => {
  return (
    <div>
      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Education Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>
      <ServicesPayment onClick={handleNext} />
    </div>
  );
};

const Step4 = () => {
  const { educationDetails } = useSelector(
    (state: any) => state.educationService as any
  );
  const subTotal =
    parseFloat(educationDetails?.variation_amount) *
    parseFloat(educationDetails?.quantity);
  const total = subTotal + parseFloat(educationDetails?.convenience_fee);
  return (
    <div className="grid place-content-center place-items-center pt-[2.5rem]">
      <PinForm
        amount={`Pay ${
          educationDetails?.service_charge
            ? formatLocalCurrency(total?.toString())
            : formatLocalCurrency(subTotal?.toString())
        }`}
        successMessage={{
          title: "Exam PIN Purchase Successful",
          message: (
            <>
              {`Your have successfully purchased ${
                educationDetails?.name?.split(" ")[0]
              } PIN of`}{" "}
              <span>{formatLocalCurrency(subTotal?.toString())}</span>
            </>
          ),
          buttonText: "Dashboard",
          link: "/services",
        }}
      />
    </div>
  );
};
