function formatLocalCurrency(value: string): string {
  // Remove all non-numeric characters except for the decimal point
  if (value === undefined || value === null) {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(Number(0.0));
  }

  if (typeof value !== "string") {
    value = parseFloat(value).toFixed(2)?.toString();
  }

  // Check if the value is truthy (not null, undefined, or an empty string)
  // If it is, remove all non-numeric characters except for the decimal point from the value
  // If it isn't, assign an empty string to priceValue
  const priceValue = value
    ? value.replace(/[^\d.]/g, "") // Remove non-numeric characters except for the decimal point
    : ""; // Assign empty string if value is falsy
  const formattedValue = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(Number(priceValue));

  return formattedValue;
}

export default formatLocalCurrency;
