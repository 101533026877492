import { Box } from "@chakra-ui/react";
import style from "./navbarcontainer.module.scss";
import Sidebar from "components/sidemenu/sidebar/SideBar";
import React, { useContext, useState } from "react";
import { css } from "@emotion/react";
import DashboardContext from "context/Dashboard/DashboardContext";

const scrollCSS = css`
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #fafafa;
    border-radius: 10px;
    box-shadow: inset 0 0 5px #7a7a7a;
    min-height: 30px; /* This makes the thumb at least 30px high */
  }
`;

const SideBarContainer = ({ bgColor }: { bgColor?: string }) => {
  const { bgSideMenuColor } = useContext(DashboardContext);
  const [isScrollable, setIsScrollable] = useState(false);

  return (
    <Box
      bg={bgSideMenuColor}
      css={scrollCSS}
      className={style.container}
      style={{
        backgroundColor: bgColor,
      }}
      onTouchStart={() => setIsScrollable(true)}
      onTouchEnd={() => setIsScrollable(false)}
      overflowY={isScrollable ? "scroll" : "hidden"}
      pb={{
        base: "1rem",
        lg: "2rem",
      }}
    >
      <Sidebar />
    </Box>
  );
};

export default SideBarContainer;
