import React, { useContext, useEffect } from "react";
import Heading from "./Heading/Heading";
import BackButton from "components/buttons/backbutton/BackButton";
import ProgressTracker from "components/ProgressTracker/ProgressTracker2";
import style from "./generalservicestyle.module.scss";
import DataForm from "../services/Form/DataForm";
import PinForm from "./Forms/EnterPin";
import ConfirmForm from "./Forms/ConfirmForm";

import ServicesPayment from "components/paymentOptions/ServicesPayment";
import DashboardContext from "context/Dashboard/DashboardContext";
import { useGetDataProviderQuery } from "features/Services/DataService/DataApiSlice";
import LoadingSpinner from "components/Spinner/Spinner";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  setDataProviders,
  setDataDetails,
} from "features/Services/DataService/DataSlice";
import { useForm } from "react-hook-form";
import extractAmountAndData from "utils/extractAmountAndData";
import formatLocalCurrency from "utils/formatLocalCurrency";
import { toast } from "react-hot-toast";
import { setSuccess, setPin } from "features/Services/ServicesSlice";
import { useCreateSubscriptionMutation } from "features/Subscription/SubscriptionApiSlice";

import { setAmountToFund } from "features/Wallet/WalletSlice";

const DataService = () => {
  //use dispatch
  const dispatch = useAppDispatch();

  //GET ALL DATA PROVIDERS
  const { data: providers } = useGetDataProviderQuery(undefined);
  const dataServices = useAppSelector((state) => state.dataService as any);
  //SET PROVIDER
  useEffect(() => {
    if (providers) {
      dispatch(setDataProviders(providers?.content));
    }
  }, [providers, dispatch]);

  const { setCurrentPagename } = useContext(DashboardContext);
  const [step, setStep] = React.useState<"1" | "2" | "3" | "4">("1");
  const [steps, setSteps] = React.useState<string[]>(["1"]);

  const handleNext = () => {
    setSteps([...steps, step]);

    if (step === "1") {
      setStep("2");
    }
    if (step === "2") {
      setStep("3");
    }
    if (step === "3") {
      setStep("4");
    }
  };

  const handleBack = () => {
    setSteps(steps.slice(0, -1));

    if (step === "1") {
      setStep("1");
    }

    if (step === "2") {
      setStep("1");
    }
    if (step === "3") {
      setStep("2");
    }
    if (step === "4") {
      setStep("3");
    }
  };
  if (step === "1") {
    setCurrentPagename("Data");
  }
  let text = "All Subscriptions";
  if (step === "2") {
    text = "Data";
    setCurrentPagename("Confirm");
  }
  if (step === "3") {
    text = "Confirm";
    setCurrentPagename("Payment Options");
  }
  if (step === "4") {
    text = "Payment options";
    setCurrentPagename("Transaction Pin");
  }
  const { control, setValue, handleSubmit } = useForm();
  const pin = useAppSelector((state) => state.services.pin as any);

  const confirmDetails = useAppSelector(
    (state) => state.dataService?.dataDetails as any
  );
  //BUY DATA MUTATION
  const [subscribe, { isLoading }] = useCreateSubscriptionMutation();
  const { add_beneficiary, formVariation } = useAppSelector(
    (state) => state.services as any
  );

  const wallet = useAppSelector((state) => state.wallet as any);

  const onSubmit = async (data: any) => {
    if (step === "1") {
      dispatch(setSuccess(false));
      const details = dataServices?.dataProviders?.find(
        (provider: any) => provider.name === data?.serviceID?.label
      );

      dispatch(
        setDataDetails({
          variation_amount: data?.variation_code?.variation_amount,
          billersCode: data.billersCode,
          renewal: data?.renewal?.value,
          ...details,
          variation_code: data?.variation_code,
          serviceID: details.serviceID,
        })
      );
      handleNext();
    }
    if (step === "2") {
      handleNext();
    }

    if (step === "4") {
      // {

      //     "serviceID":"mtn-data",
      //     "variation_code":"mtn-100mb-1000",
      //     "billersCode":"08011111111",
      //     "pin":"1111",
      //     "slug_id":1,
      //     "renewal": "off",
      //     "add_beneficiary":true,
      //     "variation_amount":100

      // }

      const data: any = {
        category_sub: "Data",
        serviceID: confirmDetails.serviceID,
        variation_code: confirmDetails.variation_code?.variation_code,
        billersCode: confirmDetails.billersCode,
        pin: pin.join(""),
        slug_id: wallet.paymentSlug,
        renewal: confirmDetails.renewal?.toLowerCase(),
        add_beneficiary,
        variation_amount: confirmDetails?.variation_amount?.replaceAll(",", ""),
      };
      if (wallet.paymentSlug === 2 && wallet.signature) {
        data.signature = wallet.signature;
      }

      try {
        const res = await subscribe(data).unwrap();
        if (res) {
          dispatch(setPin([]));
          dispatch(setSuccess(true));
        }
      } catch (error: any) {
        if (error) {
         if (error?.originalStatus === 429) {
            toast.error("Too many requests. Please try again later");
          }
          else if (error?.data?.error) {
            toast.error(error?.data?.error);
          } else if (error.status === "PARSING_ERROR") {
            toast.error("An error occured");
          } else {
            toast.error("Data Subscription failed, Please try again");
          }
        }
      }
    }
  };

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <form onSubmit={handleSubmit(onSubmit)} className={style.data}>
        <div className="lg:pl-[2.5rem] pl-[1rem]" onClick={handleBack}>
          <BackButton
            text={text}
            link={
              step === "1" ? "/subscriptions" : "/subscriptions?category=data"
            }
          />
        </div>

        <section className={style.data__section}>
          {step === "1" ? (
            <Step1 control={control} setValue={setValue} steps={steps} />
          ) : null}

          {step === "2" ? <Step2 steps={steps} /> : null}

          {step === "3" ? (
            <Step3 steps={steps} handleNext={handleNext} />
          ) : null}

          {step === "4" ? <Step4 /> : null}

          <div className="grid place-content-center place-items-center">
            {step !== "4" && step !== "3" ? (
              <button className="btn-primary">Continue</button>
            ) : null}
          </div>
        </section>
      </form>
    </>
  );
};

export default DataService;

const Step1 = ({
  steps,
  control,
  setValue,
}: {
  steps: string[];
  control: any;
  setValue: any;
}) => {
  return (
    <div>
      <Heading
        title={"Create Data Subscriptions"}
        desc="Put your data bills on reoccurring, "
        desc2="It's safe and we always deliver.."
      />
      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Data Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Confirm",
          ]}
          steps={steps}
        />
      </div>

      <DataForm control={control} setValue={setValue} />
    </div>
  );
};

const Step2 = ({ steps }: { steps: string[] }) => {
  const confirmDetails = useAppSelector(
    (state) => state.dataService?.dataDetails as any
  );
  const dispatch = useAppDispatch();
  let amountAndData = extractAmountAndData(
    confirmDetails?.variation_code?.label
  );

  useEffect(() => {
    dispatch(
      setAmountToFund(formatLocalCurrency(amountAndData?.amount as string))
    );
  }, [amountAndData, dispatch]);
  // const formVariation = useAppSelector(state => state.services?.formVariation as any)

  const confirmData = [
    {
      title: "Data Size",
      value: amountAndData?.dataSize,
    },
    {
      title: "Data Amount",
      value: formatLocalCurrency(amountAndData?.amount as string),
    },
    {
      title: "Phone Number",
      value: confirmDetails?.billersCode,
    },
    {
      title: "Renewal Status",
      value: confirmDetails?.renewal,
    },
    {
      title: "Service Fee",
      value: "NO",
    },
    {
      title: "Total",
      value: formatLocalCurrency(amountAndData?.amount as string),
    },
  ];
  return (
    <div>
      <Heading
        title={"Confirm Data Subscription"}
        desc="Please confirm your data subscription"
      />

      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Data Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>
      <ConfirmForm
        saveBeneficiary={true}
        handleSaveBeneficiary={() => {}}
        data={confirmData}
        src={
          confirmDetails?.image ? confirmDetails.image : "/Services/sv-2.svg"
        }
      />
    </div>
  );
};

const Step3 = ({
  steps,
  handleNext,
}: {
  steps: string[];
  handleNext: () => void;
}) => {
  return (
    <div>
      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Data Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Confirm",
          ]}
          steps={steps}
        />
      </div>
      <ServicesPayment title="Confirm Subscription" onClick={handleNext} />
    </div>
  );
};

const Step4 = () => {
  const confirmDetails = useAppSelector(
    (state) => state.dataService?.dataDetails as any
  );
  // let amountAndData = extractAmountAndData(confirmDetails?.variation_code)
  return (
    <div className="grid place-content-center place-items-center pt-[2.5rem]">
      <PinForm
        amount={`Create Subscription`}
        successMessage={{
          title: "Data Subscription Created",
          message: (
            <>
              {`Your have successfully created data subscription of `}
              <span>
                {formatLocalCurrency(
                  confirmDetails?.variation_amount?.replaceAll(
                    ",",
                    ""
                  ) as string
                )}
              </span>
            </>
          ),
          buttonText: "Dashboard",
          link: "/dashboard",
        }}
      />
    </div>
  );
};
