import OtherDashboard from "container/dashboard/OtherDashboard";
import SubscriptionsContainer from "container/subscriptions/Subscriptions";
import React from "react";
import { Grid } from "@chakra-ui/react";




const SubscriptionsPage = () => {
  return (
    <OtherDashboard>
      <Grid>
        <SubscriptionsContainer />

        </Grid>
    </OtherDashboard>
  )
}

export default SubscriptionsPage
