import React from "react";
import GiftCardContainer from "container/services/GiftCard/GiftCardContainer";
import OtherDashboard from "container/dashboard/OtherDashboard";

const GiftCardsPage = () => {
  return (
    <OtherDashboard>
      <GiftCardContainer />
    </OtherDashboard>
  );
};

export default GiftCardsPage;
