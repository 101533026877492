import React, { useState, useEffect } from "react";
import style from "./form.module.scss";
//import ServiceInputDropdown from "components/form/Services/DropdownInput";
import ServicesInput from "components/form/Services/Input";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { useGetElectricityServiceQuery } from "features/Services/ElectricityService/ElectricityServiceApiSlice";
import { setElectricityService } from "features/Services/ElectricityService/ElectricityServiceSlice";
import ReactLoading from "react-loading";
import DropdownWithImage from "components/form/Services/ServiceSelect";
import { useWatch } from "react-hook-form";
import { useDisclosure } from "@chakra-ui/react";
import SelectBeneficiaryModal from "components/Beneficiaries/SelectBeneficiaryModal";
import useRenderRenewalStatus from "hooks/useRenderRenewalStatus";

const ElectricityForm = ({
  control,
  setValue,
}: {
  control: any;
  setValue: any;
}) => {
  //dispatch
  const dispatch = useAppDispatch();
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [checked, setChecked] = useState<boolean>(false);
  const { electricityProviders, electricityService } = useAppSelector(
    (state: any) => state.electricityService as any
  );
  const [id, setId] = useState<string>("");

  const watch = useWatch({
    control,
    name: "provider",
  });

  //get electricity service
  const { data, isLoading } = useGetElectricityServiceQuery(watch?.serviceID , {
    skip: !watch?.serviceID,
  });

  useEffect(() => {
    if (data) {
      setValue('meter' , {

      })
      dispatch(setElectricityService(data));
    }
  }, [data, dispatch]);

  const getItem = (item: any) => {
    //find serviceID in provider
    const serviceID = electricityProviders?.find(
      (provider: any) => provider.name === item
    )?.serviceID;
    setId(serviceID);
  };

  const electricityProviderOptions = electricityProviders?.map((item: any) => ({
    ...item,
    value: item.serviceID,
    label: item.name,
  }));

  const meterOptions = electricityService?.content?.varations?.map(
    (item: any) => ({
      ...item,
      value: item.serviceID,
      label: item.name,
    })
  );
  const {electricity} = useAppSelector(state => state.beneficiary)


  const renewalStatusData = useRenderRenewalStatus();

  return (
    <div className="flex items-center justify-center w-full relative">
      {isLoading ? (
        <div className="grid place-content-center place-items-center absolute right-[50%] top-0">
          {" "}
          <ReactLoading
            type={"spinningBubbles"}
            color={"var(--primary)"}
            height={50}
            width={50}
          />
        </div>
      ) : null}

      <div className={style.form}>
        <DropdownWithImage
          rules={{
            required: "Field cannot be empty.",
          }}
          setValue={setValue}
          control={control}
          name="provider"
          defaultValue=""
          title={"Select Provider"}
          placeholder={"Select electricity provider"}
          options={electricityProviderOptions}
        />
        <DropdownWithImage
          rules={{
            required: "Field cannot be empty.",
          }}
          defaultValue=""
          title={"Meter Type"}
          name={"meter"}
          placeholder={"Select Meter Type"}
          control={control}
          options={meterOptions}
          setValue={setValue}
        />
        <ServicesInput
          rules={{
            required: "Field cannot be empty.",
          }}

          label={"Meter Number"}
          name={"meter_number"}
          placeholder={"Meter Number"}
          type={""}
          control={control}
          maxLength={20}
          defaultValue={electricity as unknown as string}
          setValue={setValue}
        />
          <p className='hover:font-bold cursor-pointer' onClick={onOpen}>
                    Select Beneficiary
                </p>
        <ServicesInput
          rules={{
            required: "Field cannot be empty.",
          }}
          defaultValue=""
          label={"Enter Amount"}
          name={"amount"}
          placeholder={"Enter Unit Amount"}
          onChange={() => {
            return null;
          }}
          control={control}
        />
        <DropdownWithImage
          setValue={setValue}
          control={control}
          options={renewalStatusData}
          defaultValue={renewalStatusData[0]}
          name="renewal"
          title={"Renewal Status"}
          placeholder={"Set renewal status"}
        />
        <div
          className="flex gap-[1.4rem] items-center cursor-pointer "
          onClick={() => setChecked(!checked)}
        >
          {checked ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" rx="8" fill="#246BFD" />
              <path
                d="M8 12L11 15L17 9"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1.5"
                y="1.5"
                width="21"
                height="21"
                rx="6.5"
                stroke="var(--primary)"
                stroke-width="3"
              />
            </svg>
          )}
          <label htmlFor="checkbox">
            Send to third party recipient (optional)
          </label>
        </div>
        {checked ? (
          <>
            <div>
              <ServicesInput
                control={control}
                defaultValue=""
                label={"Family or Friend Email"}
                name={"third_party_email"}
                placeholder={"Enter Email Address"}
                onChange={() => {
                  return null;
                }}
                type={"email"}
              />
              <p className="pt-[.63rem] text-gray-600 text-[1rem] font-[500] ">
                We will send meter token to recipient for FREE
              </p>
            </div>
            <div>
              <ServicesInput
                control={control}
                defaultValue=""
                label={"Third Party Phone Number"}
                name={"third_party_phone"}
                placeholder={"Enter Phone Number"}
                onChange={() => {
                  return null;
                }}
                type={"tel"}
              />
              <p className="pt-[.6rem]  text-gray-600 text-[1rem] font-[500]">
                You will be charge N10 SMS gateway fee
              </p>
            </div>
          </>
        ) : null}
      </div>
      <SelectBeneficiaryModal category='Electricity' isOpen={isOpen} onClose={onClose} />
    </div>
  );
};

export default ElectricityForm;
