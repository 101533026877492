/* eslint-disable react-hooks/exhaustive-deps */
import style from "./dashboard.module.scss";
import React, { useState, useEffect, useRef } from "react";
import DashboardContext from "context/Dashboard/DashboardContext";
import TopMenu from "../topmenu/TopMenu";
import SideBarContainer from "../navbar/NavBarContainer";

import { useAppDispatch } from "hooks/redux";
import { useRefreshQuery } from "features/Auth/RefreshApiSlice";
import { login } from "features/Auth/AuthSlice";
// import { useProfileQuery } from 'features/Profile/ProfileApiSlice'
// import { setProfile } from 'features/Profile/ProfileSlice'
import { Toaster } from "react-hot-toast";
import LoadingSpinner from "components/Spinner/Spinner";
import {
  useGetFundingQuery,
  useGetTransactionsQuery,
} from "features/Transactions/TransactionApiSlice";
import {
  setTransactions,
  setFunding,
} from "features/Transactions/TransactionSlice";

import { useIdle, useCookie } from "react-use";
import { useLogoutMutation } from "features/Auth/LogoutApiSlice";
import { Box } from "@chakra-ui/react";

const Dashboard = ({ children }: { children: React.ReactNode }) => {
  const [currentPagename, setCurrentPagename] = useState<string>("Dashboard");

  const [bgContentColor, setBgContentColor] = useState<string>("#FFF");
  const [bgTopMenuColor, setBgTopMenuColor] = useState<string>("#FFF");
  const [bgSideMenuColor, setBgSideMenuColor] =
    useState<string>("var(--gray-light)");
  const [logout] = useLogoutMutation();

  //remember
  const [remember, , deleteRemember] = useCookie("remember");
  const [jara, , deleteJaraAccess] = useCookie("jara_access");

  const isIdle = useIdle(600000);

  useEffect(() => {
    const logoutUser = async () => {
      await logout(undefined);
      deleteRemember();
      deleteJaraAccess();
      window.location.href = "/sign-in";
    };
    if (isIdle && !remember) {
      logoutUser();
    }
  }, [isIdle, remember]);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  useEffect(() => {
    scrollToTop();
  }, [currentPagename]);

  const dispatch = useAppDispatch();

  const { data, isLoading, isFetching } = useRefreshQuery(undefined, {
    skip: !jara,
  });

  useEffect(() => {
    dispatch(login(data));
  }, [data, dispatch]);

  // //Get Transaction History
  const { data: transactions, isLoading: loadingTransactions } =
    useGetTransactionsQuery(undefined);

  useEffect(() => {
    dispatch(setTransactions(transactions));
  }, [dispatch, transactions]);

  const { data: funding, isLoading: loadingFunding } =
    useGetFundingQuery(undefined);

  useEffect(() => {
    dispatch(setFunding(funding));
  }, [dispatch, funding]);
  const [isScrolled, setIsScrolled] = useState(false);



  useEffect(() => {

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);


    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        setCurrentPagename,
        setBgTopMenuColor,
        setBgContentColor,
        setBgSideMenuColor,
        currentPagename,
      }}
    >
      <Toaster
        toastOptions={{
          duration: 4000,
          style: {
            fontSize: "16px",
          },
        }}
      />
      {isLoading || isFetching || loadingTransactions || loadingFunding ? (
        <LoadingSpinner />
      ) : null}
      <main className={style.container}>
        <section
          className={`${style.sidebar} bg-[${bgSideMenuColor}] `}
          style={{
            background: bgSideMenuColor,
          }}
        >
          <SideBarContainer />
        </section>
        <section className={`${style.content}  bg-[${bgContentColor}] `}>
          <Box
            // paddingBottom={{
            //   base: isScrolled ? "1rem" : "0",
            //   lg: isScrolled ? "1.25rem" : "0",
            // }}
            className={style.topmenu__container}
            style={{
              backgroundColor: bgTopMenuColor,
            }}
          >
            <TopMenu />
          </Box>

          <section
            ref={scrollContainerRef}
            className={`${style.content__bottom} bg-[${bgContentColor}] min-h-[100vh]`}
          >
            {children}
          </section>
        </section>
      </main>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
