import React  from "react";



import { useController } from "react-hook-form";

import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Grid,
  Text,

} from "@chakra-ui/react";


const NewInput = ({
  control,
  defaultValue,
  name,
  label,
  placeholder,
  setValue,
  open,
  disabled
}: {
  control: any;
  defaultValue: any;
  name: string;
  label: string;
  placeholder?: string;
  setValue?: (value: any, name: string) => void;
  open?: boolean
  disabled?: boolean
}) => {

  const { field } = useController({
    control,
    name,
    defaultValue
  });

  return (
    <FormControl>
      <Grid>
        <FormLabel>
          <Text
            color={"var(--dark-text"}
            fontWeight={"500"}
            fontSize={".875rem"}
          >
            {label}
          </Text>
        </FormLabel>




            <Input
              placeholder={placeholder}
              {...field}
              _placeholder={{
                color: "var(--text-1)",
                fontWeight: "500",
              }}
              color = {open? 'var(--dark-text)': 'var(--text-1)'}
              fontSize={".875rem"}
              borderRadius={"0.625rem"}
              fontWeight={"500"}

            //   padding={"1.25rem 25px 1.25rem 25px"}
              focusBorderColor="var(--primary)"
              bg={'inherit'}
              isDisabled={disabled}
            />

      </Grid>
    </FormControl>
  );
};

export default NewInput;
