import React from "react";
import style from "./leftmenu.module.scss";

import CardSearch from "./CardSearch";
import { Text } from "@chakra-ui/react";
import { useSearchParams, useLocation } from "react-router-dom";

const LeftMenu = ({ name }: { name: string }) => {
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const pathname = location.pathname;

  return (
    <section className={style.leftmenu}>
      {category === "gift card" || pathname === "/gift-cards" ? (
        <CardSearch />
      ) : (
        <Text
          fontSize={{
            base: "1rem",
            lg: "1.2rem",
            "2xl": "1.563rem",
          }}
          as={"h1"}
          className={style.leftmenu__title}
          data-testid="leftmenu-title"
          aria-label={name}
        >
          {name}
        </Text>
      )}
    </section>
  );
};

export default LeftMenu;
