import React from "react";
import { Button, Text, Flex } from "@chakra-ui/react";

const ProceedDialog = ({
  handleProceed,
  handleContinueShopping,
}: {
  handleProceed: () => void;
  handleContinueShopping?: () => void;
}) => {
  return (
    <Flex
      direction="column"
      p={5}
      gap={{
        base: 4,
        md: 8,
        lg: 10,
        xl: 12,
      }}
    >
      <Text fontWeight={"bold"} fontSize="lg" mb={4} textAlign={"center"}>
        Would you like to continue shopping or proceed to your cart?
      </Text>
      <Flex justify="space-between" w="100%" maxW="400px">
        <Button
          borderRadius={"xl"}
          colorScheme="gray"
          bg={"var(--gray-bg)"}
          onClick={handleContinueShopping}
          fontSize={{
            base: "sm",
            md: "md",
          }}
        >
          Continue Shopping
        </Button>
        <Button
          borderRadius={"xl"}
          color={"white"}
          bg={"var(--primary)"}
          onClick={handleProceed}
          _hover={{
            bg: "var(--primary)",
            opacity: 0.8,
          }}
          fontSize={{
            base: "sm",
            md: "md",
          }}
        >
          Proceed to Cart
        </Button>
      </Flex>
    </Flex>
  );
};

export default ProceedDialog;
