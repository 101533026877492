import React from "react";
import TokenPage from "./Token";

const Pin = ({
  title = "Login Pin",
  tokenNumber,
  myPin,
  concentrate,
}: {
  title?: string;
  tokenNumber?: number;
  myPin?: any;
  concentrate?: boolean;
}) => {
  return (
    <section>
      <TokenPage
        concentrate={concentrate}
        myPin={myPin}
        tokenNumber={tokenNumber}
        title={title}
      />
    </section>
  );
};

export default Pin;
