// TableHeader.tsx

import React from 'react';
// import styles from '../body/body.module.scss';
const TableHeader: React.FC = () => {
  return (
    <section className='xl:flex md:flex justify-between items-center w-full gap-[2.5rem]  lg:hidden hidden'>
      <h5 className={`text-[var(--gray-text)] text-[.75rem] font-[600] uppercase w-[40%]` }>Services</h5>
      <div className={'grid grid-cols-[33%_33%_33%] items-center w-full'}>

        <h5 className={`text-[var(--gray-text)] text-[.75rem] font-[600] uppercase text-center ` }>Payment Type</h5>
        <h5 className={`text-[var(--gray-text)] text-[.75rem] font-[600] uppercase text-center ` }>Amount</h5>
        <h5 className={`text-[var(--gray-text)] text-[.75rem] font-[600] uppercase text-right ` }>Date/Time</h5>

      </div>
    </section>

  );
};

export default TableHeader;