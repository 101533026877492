import React from "react";

import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/react";

interface modal {
  handleClose: () => void;
  open: boolean;

  children: React.ReactNode;
}
const ModalContainer: React.FC<modal> = ({ handleClose, open, children }) => {
  //close the modal when the component on click outside child

  // const handleClickOutside = () => {
  //     if (open) {
  //         handleClose();
  //     }
  // };

  return (
    <Modal
      isCentered
      isOpen={open}
      onClose={handleClose}
      size="lg"
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
        {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalContainer;
