import React, { useContext, useEffect, useState, Suspense } from "react";
import style from "./profilecontainer.module.scss";
import DashboardContext from "context/Dashboard/DashboardContext";
// import ProfileDateInput from 'components/Profile/ProfileDateInput'
import { useForm } from "react-hook-form";
// import ProfileInput from 'components/Profile/ProfileInput'
// import ProfileIconInput from 'components/Profile/ProfileIconInput'
import { useAppSelector } from "hooks/redux";
import Spinner from "components/Spinner/Spinner";
import {
  useProfileQuery,
  useUpdateProfileMutation,
} from "features/Profile/ProfileApiSlice";
import { Toaster, toast } from "react-hot-toast";

import formatDateToYYYYMMDD from "utils/formatYYYYMMDD";

import DateInput from "components/form/DateInput";
import EmailInput from "components/form/EmailInput";
import NewInput from "components/form/NewInput";
// import DropdownWithImage from 'components/form/DropdownWithImage'
import ProfileDropdown from "components/form/ProfileDropdown";

// {
//     "first_name": "John",
//     "last_name": "Doe",
//     "gender": "Male",
//     "email": "olawaleoyewale2102@gmail.com",
//     "mobile_number": "+2348052138636",
//     "date_of_birth": "1990-01-01",
//     "country": "United States",
//     "state": "California",
//     "address": "123 Main St"
//   }

// interface IProfile {
//   first_name: string;
//   last_name: string;
//   gender: string;
//   email: string;
//   mobile_number: string;
//   date_of_birth: Date;
//   country: string;
//   state: string;
//   address: string;
// }

const ProfileContainer = () => {
  const {
    setCurrentPagename,
    setBgContentColor,
    setBgSideMenuColor,
    setBgTopMenuColor,
  } = useContext(DashboardContext);
  const { user } = useAppSelector((state) => state.auth as any);

  const { data: profile } = useProfileQuery(user?.data?.id, {
    skip: !user?.data?.id,
  });

  // const [profile?.full_profile?, setProfileData] = useState<any>({})
  // useEffect(() => {
  //     if (profile) {

  //         setProfileData(profile?.full_profile)
  //     }
  // }, [profile])

  // const defaultValues: IProfile = {
  //   first_name: profile?.full_profile?.first_name || "",
  //   last_name: profile?.full_profile?.last_name || "",
  //   gender: profile?.full_profile?.gender || "",
  //   email: profile?.full_profile?.email || "",
  //   mobile_number: profile?.full_profile?.mobile_number || "",
  //   date_of_birth: profile?.full_profile?.date_of_birth || "",
  //   country: profile?.full_profile?.country || "",
  //   state: profile?.full_profile?.state || "",
  //   address: profile?.full_profile?.address || "",
  // };

  const {
    control,
    handleSubmit,
    setValue,
    // formState: { isDirty },
  } = useForm({});
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  useEffect(() => {
    setCurrentPagename("Edit Profile");
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgContentColor ? setBgContentColor("var(--gray-light)") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgTopMenuColor ? setBgTopMenuColor("var(--gray-light)") : null;
  }, [
    setBgContentColor,
    setBgSideMenuColor,
    setCurrentPagename,
    setBgTopMenuColor,
  ]);

  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => setOpen(!open);

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append(
      "first_name",
      data.first_name || profile?.full_profile?.first_name
    );
    formData.append(
      "last_name",
      data.last_name || profile?.full_profile?.last_name
    );
    formData.append(
      "gender",
      data.gender?.value || profile?.full_profile?.gender
    );
    formData.append("email", data.email || profile?.full_profile?.email);
    formData.append(
      "mobile_number",
      data.mobile_number || profile?.full_profile?.mobile_number
    );

    formData.append("country", data.country || profile?.full_profile?.country);
    formData.append("state", data.state || profile?.full_profile?.state);
    formData.append("address", data.address || profile?.full_profile?.address);

    formData.append(
      "date_of_birth",
      formatDateToYYYYMMDD(data?.date_of_birth as Date) ||
        formatDateToYYYYMMDD(profile?.full_profile?.date_of_birth as Date)
    );

    try {
      const res = await updateProfile(formData).unwrap();
      if (res?.profile) {
        toast.success("Profile updated successfully");
      }
    } catch (error: any) {
      if (error?.data?.errors?.date_of_birth) {
        toast.error(error?.data?.errors?.date_of_birth[0]);
      } else if (error?.data?.errors?.email) {
        toast.error(error?.data?.errors?.email[0]);
      } else if (error?.data?.errors?.first_name) {
        toast.error(error?.data?.errors?.first_name[0]);
      } else if (error?.data?.errors?.gender) {
        toast.error(error?.data?.errors?.gender[0]);
      } else if (error?.data?.errors?.last_name) {
        toast.error(error?.data?.errors?.last_name[0]);
      } else if (error?.data?.errors?.mobile_number) {
        toast.error(error?.data?.errors?.mobile_number[0]);
      } else if (error?.data?.errors?.country) {
        toast.error(error?.data?.errors?.country[0]);
      } else if (error?.data?.errors?.state) {
        toast.error(error?.data?.errors?.state[0]);
      } else if (error?.data?.errors?.address) {
        toast.error(error?.data?.errors?.address[0]);
      } else if (error?.data?.errors?.non_field_errors) {
        toast.error(error?.data?.errors?.non_field_errors[0]);
      } else {
        toast.error(error?.data?.message);
      }
    }
  };

  const [message, setMessage] = useState("");

  const handleMouseEnter = () => {
    setMessage(open ? "Close" : "Click to edit");
  };

  const handleMouseLeave = () => {
    setMessage("");
  };

  return (
    <main className={style.profile__container}>
      <Toaster
        toastOptions={{
          duration: 6000,
          style: {
            fontSize: "16px",
          },
        }}
      />
      {isLoading ? <Spinner /> : null}
      <section className={style.header}>
        <h1>Account Information</h1>
        <p>Update your account information</p>
      </section>
      <section className={style.personal_info}>
        <h1>Personal Information</h1>
        <div
          className={style.edit}
          onClick={handleClickOpen}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {message && <span className={style["edit-message"]}>{message}</span>}
          <span>
            <img
              src="/Profile/edit.svg"
              alt="edit"
              className="w-[2rem] h-[2rem]"
            />
          </span>
          <span>Edit</span>
        </div>
      </section>
      <Suspense fallback={<Spinner />}>
        {profile?.full_profile ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={style.form}
            style={{
              pointerEvents: open ? "auto" : "none",
            }}
          >
            <div className={style.form__input__container}>
              <NewInput
                open={open}
                setValue={setValue}
                defaultValue={profile?.full_profile?.first_name}
                control={control}
                name="first_name"
                label="First Name"
                placeholder="First Name"
                disabled={user?.data?.virtual_account?.length ? true : false}
              />
              <NewInput
                open={open}
                setValue={setValue}
                defaultValue={profile?.full_profile?.last_name}
                control={control}
                name="last_name"
                label="Last Name"
                placeholder="Last Name"
                disabled={user?.data?.virtual_account?.length ? true : false}
              />
              <DateInput
                open={open}
                setValue={setValue}
                defaultValue={profile?.full_profile?.date_of_birth}
                control={control}
                name="date_of_birth"
                label="Date of Birth"
                disabled={user?.data?.virtual_account?.length ? true : false}
              />
              <NewInput
                open={open}
                setValue={setValue}
                defaultValue={profile?.full_profile?.mobile_number}
                name="mobile_number"
                label="Phone Number"
                placeholder="Phone number"
                control={control}
                disabled={user?.data?.virtual_account?.length ? true : false}
              />
              <EmailInput
                open={open}
                setValue={setValue}
                defaultValue={profile?.full_profile?.email}
                control={control}
                name="email"
                label="Email"
                placeholder="Email address"
                disabled={true}
              />
              <NewInput
                open={open}
                setValue={setValue}
                defaultValue={profile?.full_profile?.country}
                control={control}
                name="country"
                label="Country"
                placeholder="Country"
              />
              <NewInput
                open={open}
                setValue={setValue}
                defaultValue={profile?.full_profile?.state}
                control={control}
                name="state"
                label="State"
                placeholder="State"
              />
              <NewInput
                open={open}
                setValue={setValue}
                defaultValue={profile?.full_profile?.address}
                control={control}
                name="address"
                label="Address"
                placeholder="Address"
              />
              <ProfileDropdown
                open={open}
                placeholder={profile?.full_profile?.gender}
                options={[
                  { value: "male", label: "Male" },
                  { value: "female", label: "Female" },
                ]}
                name="gender"
                control={control}
                title="Gender"
                defaultValue={profile?.full_profile?.gender}
              />
            </div>
            <button
              disabled={isLoading}
              className="btn-primary disabled:cursor-not-allowed"
              type="submit"
            >
              Update Profile
            </button>
          </form>
        ) : null}
      </Suspense>
    </main>
  );
};

export default ProfileContainer;
