import { authApi } from "app/ApiSlice";

const WalletApiSlice = authApi.injectEndpoints({
  endpoints: (builder) => ({
    //Transaction Endpoint
    getPaymentChannels: builder.query({
      query: () => `/v1/payment/paymentchannels`,
      transformResponse(baseQueryReturnValue, meta, arg) {
        return baseQueryReturnValue;
      },
    }),
    confirmReceiverDetail: builder.mutation({
      query: (body) => ({
        url: "/v1/wallet/confirm-receiver-detail",
        method: "POST",
        body,
      }),
    }),

    transferToWallet: builder.mutation({
      query: (body) => ({
        url: "/v1/wallet/send-money",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Auth"],
    }),
    //Fund wallet
    fundWallet: builder.mutation({
      query: (data) => ({
        url: data.url,
        method: "POST",
        body: data.body,
      }),
      // invalidatesTags: ['Auth']
    }),
    verifyBTC: builder.mutation({
      query: (address) => ({
        method: "GET",
        url: `/v1/payment/verify-bitcoin?address=${address}`,
      }),
      extraOptions: { maxRetries: 10 },
    }),
    BitcoinCallback: builder.mutation({
      query: (body) => ({
        method: "GET",
        url: `/v1/payment/bitcoin/callback?addr=${body.addr}&status=${body.status}&txid=${body.txid}&value=${body.value}`,
      }),

      invalidatesTags: ["Auth"],
    }),
    SquadcoCallback: builder.mutation({
      query: (body) => ({
        timeout: 100000, // timeout = 100 seconds
        method: "GET",
        url: `/v1/payment/sqaudco/callback?reference=${body.reference}`,
      }),
      invalidatesTags: ["Auth"],
    }),
    FlutterCallback: builder.mutation({
      query: (body) => ({
        method: "GET",
        url: `/v1/payment/flutterwave/callback?status=${body.status}&tx_ref=${body.reference}`,
      }),
      invalidatesTags: ["Auth"],
      extraOptions: { maxRetries: 10 },
    }),
    PaystackCallback: builder.mutation({
      query: (body) => ({
        method: "GET",
        url: `/v1/payment/paystack/callback?reference=${body.reference}`,
      }),
      invalidatesTags: ["Auth"],
      extraOptions: { maxRetries: 10 },
    }),
    GetPaymentOption: builder.query({
      query: () => ({
        url: "/v1/payment/payment_option",
        method: "GET",
      }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        return baseQueryReturnValue;
      },
      providesTags: ["PaymentOptions"],
    }),


    addCard: builder.query({
      query: (body) => ({
        url: "/v1/payment/addcard",
        method: "GET",
      }),

    }),

    thirdPartyAddCard: builder.mutation({
      query: ({ link, body }) => ({
        url: link,
        method: "POST",
        body,
      }),
      invalidatesTags: ["PaymentOptions"],


    }),
    setDefaultCard: builder.mutation({
      query: (body) => ({
        url: "/v1/payment/set-default-card",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["PaymentOptions"],
    }),
    deleteCard: builder.mutation({
      query: (body) => ({
        url: "/v1/payment/card",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["PaymentOptions"],
    }),
  }),
});

export const {
  useGetPaymentOptionQuery,
  useBitcoinCallbackMutation,
  useGetPaymentChannelsQuery,
  useConfirmReceiverDetailMutation,
  useTransferToWalletMutation,
  useSquadcoCallbackMutation,
  usePaystackCallbackMutation,
  useFundWalletMutation,
  useVerifyBTCMutation,
  useFlutterCallbackMutation,
  useThirdPartyAddCardMutation,
  useAddCardQuery,
  useDeleteCardMutation,
  useSetDefaultCardMutation,
} = WalletApiSlice;
