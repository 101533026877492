import Input from "components/form/Auth/Input";
import Pin from "components/form/Auth/Pin/Pin";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ContainerWrapper from "../container/Container";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSignInMutation } from "features/Auth/AuthApiSlice";
import { Toaster, toast } from "react-hot-toast";
import Device from "utils/device";
import Spinner from "components/Spinner/Spinner";
import { useAppDispatch } from "hooks/redux";
import { login } from "features/Auth/AuthSlice";
// import { createBrowserId } from "utils/utilsCookies";
import KeepUserLoggedInForDaysCookie from "utils/KeepUserLoggedInFor30DaysCookie";

const SigninContainer = () => {
  // const deviceId = createBrowserId();

  //clear storage
  useEffect(() => {
    sessionStorage.removeItem("mail");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("user_mail");
    localStorage.removeItem("access");
  }, []);

  const device = Device();
  const location = useLocation();

  const from = location.state?.from || "/";

  const { control, handleSubmit, register , watch } = useForm({
    defaultValues: {
      login_pin: "",
      device_id: "web",
      os: device.os,
      device_name: device.device_name,
      remember: false,
      email: ''
    },
  });

  const [pin, setPin] = useState<string[]>([]);
  const [email, setEmail] = useState<string>("");
  const [signIn, { isLoading }] = useSignInMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit = async (data: any) => {
    dispatch(login({}));
    localStorage.setItem("remember", data.remember);
    const formInputs = {
      login_pin: pin.join(""),
      device_id: data.device_id,
      os: data.os,
      device_name: data.device_name,
      email: data.email,
    };

    try {
      const res = await signIn(formInputs).unwrap();

      if (res.access_token) {
        toast.success("Login Successful");
        dispatch(login(res));


        KeepUserLoggedInForDaysCookie(
          data.remember ? 30 : 1,
          res.access_token,
          "jara_access"
        );
        KeepUserLoggedInForDaysCookie(
          data.remember ? 30 : 1,
          data.remember,
          "remember"
        );
        navigate(from);
      }
    } catch (error: any) {
      if (error.status === 401) {
        toast.error("Wrong email or password");
        sessionStorage.setItem("user_mail", data.email);
        setEmail(data.email);
      }
      else{
        toast.error(error?.data?.message || error?.error);
      }
    }
  };

  return (
    <ContainerWrapper>
      <Toaster
        toastOptions={{
          duration: 4000,
          style: {
            fontSize: "16px",
          },
        }}
      />
      {isLoading ? <Spinner /> : null}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="2xl:pt-[3.1875rem] md:pt-[3.1875rem] p-[1.25rem] md:flex md:flex-col md:gap-[4.375rem] lg:gap-0 gap-0 lg:grid grid place-items-start place-content-center lg:px-0 h-[100vh] max-w-full"
      >
        <a href="https://jarapay.co/"  >
        <img
          src="/jarapay.svg"
          alt="jarapay"
          width={130}
          height={35}
          className="lg:w-[8.125rem] h-[2.1875rem] 2xl:mb-[5.0625rem] mb-[2.5rem]"

        />
        </a>
        <section className="max-w-[max-content]">
          {/* Welcome Message */}
          <section className="flex gap-[2.8125rem] flex-col items-start">
            <div className="">
              <h1 className="text-[var(--dark-text)] text-[1.875rem] font-semibold  lg:pb-[0.5rem] font-nunito">
                Welcome back
              </h1>
              <h6 className="font-[400] text-[var(--text-1)] text-[1rem] ">
                Please enter your details
              </h6>
            </div>
            {/* Input Boxes */}
            <div className="flex flex-col gap-[0.9375rem]">
              <Input
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please Enter a Valid Email.",
                  },
                }}
                name="email"
                control={control}
                title="Email Address"
                type="email"
                placeholder="Email"

              />
              <Pin myPin={setPin} tokenNumber={6} title="Login PIN" concentrate= {
                watch("email") ? true: false
              } />
              <div className="flex gap-[0.5625rem] mt-[0.1875rem] items-center">
                <input
                  placeholder="checkbox"
                  type="checkbox"
                  {...register("remember")}
                />
                <p className="text-gray-900 font-[500] text-[0.875rem]">
                  Remember for 30 Days
                </p>
              </div>
            </div>
          </section>

          {/* Sign In Button */}
          <div className="grid place-items-center">
            <button
              disabled={isLoading || pin.join("").length < 6}
              className="disabled:cursor-not-allowed btn-primary transition-all mt-[1.5625rem] xl:mb-[1.25rem] mb-[1.5rem] 2xl:mb-[1.5rem]"
            >
              Sign in
            </button>
          </div>
          <div className="flex items-center justify-center gap-[.125rem] lg:w-[25.25] mb-[.5rem] 2xl:mb-6">
            <p className="text-[var(--gray-text)] text-[.875rem] font-[500] text-center">
              {"Don't have an account?"}
            </p>

            <h6 className="text-dark text-[14px] font-[500] text-center relative">
              <Link to={"/sign-up"} className="hover:font-bold">
                Sign up for free
              </Link>
              <span className="absolute top-[1.2rem] left-[.2rem]">
                <img
                  src="/Auth/rule.png"
                  alt="rule"
                  width={96}
                  height={10}
                  className="w-[6rem] h-[.6rem]"
                />
              </span>
            </h6>
          </div>

          {/* Reset Pin Link */}
          <div className="text-gray-900 text-[0.875rem] lg:w-[25.25rem] font-[500] mb-[1rem] 2xl:mb-[3rem] flex place-items-center justify-center">
            <Link
              to={email ? "/reset-pin" : "/request-pin-reset"}
              className="text-dark font-[0.875rem]  block"
            >
              Reset Login PIN?
            </Link>
          </div>
        </section>
      </form>
    </ContainerWrapper>
  );
};

export default SigninContainer;
