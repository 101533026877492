import React, { useEffect } from "react";
import { useController } from "react-hook-form";
import NumberFormatter from "utils/NumberFormatter";

/**
 *
 * @param param0
 * @returns
 */
interface InputProps {
  title: string;
  placeholder: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  type?: string;
  control: any;
  name: string;
  rules?: any;
  maxLength?: number;
  autoFocus?: boolean;
}

const Input: React.FC<InputProps> = ({
  title,
  placeholder = "Enter your email",
  type,
  control,
  name,
  rules,
  maxLength,
  autoFocus,
}) => {
  const {
    field: { ref, ...inputProps },
    formState: { errors },
  } = useController({
    control,
    name,
    rules,
  });

  useEffect(() => {
    if (name === "login_pin" && inputProps.value) {
      inputProps.onChange(NumberFormatter(inputProps.value));
    }
  }, [name, inputProps.value, inputProps]);

  //if name = pin, dont accept any characters other than numeric and special characters

  return (
    <div className="flex flex-col w-full md:w-[25.25rem]">
      <label className="mb-[.625rem] text-[.875rem] font-[500]">{title}</label>
      <input
        autoFocus={autoFocus}
        maxLength={maxLength}
        {...inputProps}
        // active
        className={`w-full text-[.875rem] text-[var(--text-1)]  pt-[.93rem] pb-[1rem] pl-[1.53rem] pr-[1.2rem] border border-[#F2F2F2] outline-none active:outline-none active:border-primary focus:border-primary  rounded-[.62rem] focus:outline-none`}
        placeholder={placeholder}
        type={type ? type : "text"}
      />
      <p className="text-red text-[.75rem]">
        {errors && errors[name] ? (errors[name]?.message as string) : ""}
      </p>
    </div>
  );
};

export default Input;
