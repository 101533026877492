import React from "react";
import style from "./confirmfund.module.scss";
import WalletInfo from "components/walletTransfer/WalletInfo/WalletInfo";
interface ConfirmFundProps {
  handleClick: () => void;
}

const ConfirmFund: React.FC<ConfirmFundProps> = ({ handleClick }) => {
  return (
    <div className={style.confirm__fund}>
      <h1 className={style.confirm__fund__title}>
        How much would you like to fund?
      </h1>
      <div>
        <div className="grid gap-[0.9rem] md:w-[24rem] w-full 2xl:mb-[10.5625rem] mb-[10.9375rem]">
          <h5 className={style.confirm__fund__form__title}>Enter Amount</h5>
          <WalletInfo />
        </div>

        <div className="grid place-items-center">
          <button className="btn-primary" onClick={handleClick}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmFund;
