import React from "react";
import style from "./sidebar.module.scss";
import Menu from "../menu/Menu";
import { useNavigate } from "react-router-dom";

export const Sidebar = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <nav className={`${style.sidebar}`} aria-label="side menu">
      <section onClick={handleClick} className={style.logo}>
        <img
          src="/jarapay.svg"
          width={130}
          height={35}
          alt="logo"
          className="w-[150px] h-[35px]"
        />
      </section>
      <section className={style.menu}>
        <Menu />
      </section>
    </nav>
  );
};

export default Sidebar;
