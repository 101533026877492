import { createSlice  , PayloadAction} from "@reduxjs/toolkit";

//action type



const initialState : any= {
    channels : [],
    amountToFund:'',
    rawAmount: '',
    walletTranferDetails:  {

    },
    payment : {
        paymentUrl : '',
        paymentType : '',

    },
    paymentOptions : [],
    paymentSlug: 1,
    signature: ''

}


const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        setChannels : (state , action) => {
            state.channels = action.payload
        },
        setAmountToFund : (state , action) => {
            console.log(action.payload)
            //remove all none numeric characters, except for decimal point
            state.amountToFund = action.payload.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            state.rawAmount = action.payload;
        },
        setWalletTranferDetails : (state , action) => {
            state.walletTranferDetails = action.payload
        },
        setPayment : (state , action:PayloadAction<{paymentUrl : string , paymentType : 'Squadco' | "Flutterwave"| "PayStack" | "Bitcoin" }>) => {
            state.payment = action.payload
        },
        setPaymentOptions : (state , action) => {

            state.paymentOptions = action.payload?.data
        },
        setPaymentSlug : (state , action:PayloadAction<number>) => {

            state.paymentSlug = action.payload


        },
        setSignature : (state, action:PayloadAction<string>) => {

            state.signature = action.payload


        }



    }
})

export const {  setPaymentSlug , setChannels , setAmountToFund , setWalletTranferDetails , setPayment  , setPaymentOptions , setSignature}  = walletSlice.actions
export default walletSlice.reducer