import React, { useEffect } from "react";
import Heading from "./Heading/Heading";
import BackButton from "components/buttons/backbutton/BackButton";
import ProgressTracker from "components/ProgressTracker/ProgressTracker2";
import style from "./generalservicestyle.module.scss";
import PinForm from "./Form/EnterPin";
import ConfirmForm from "./Form/ConfirmForm";

import ServicesPayment from "components/paymentOptions/ServicesPayment";
import ListInsurance from "components/Insurance/ListInsurance";
import DashboardContext from "context/Dashboard/DashboardContext";
import { useGetInsuranceProviderQuery } from "features/Services/Insurance/InsuranceApiSlice";
import { setInsuranceProvider } from "features/Services/Insurance/InsuranceServiceSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useForm } from "react-hook-form";
import ThirdPartyForm from "./Form/ThirdPartyForm";
import HealthInsuranceForm from "./Form/HealthInsuranceForm";
import { setInsuranceDetails } from "features/Services/Insurance/InsuranceServiceSlice";
import { setAmountToFund } from "features/Wallet/WalletSlice";
import { useBuyInsuranceMutation } from "features/Services/Insurance/InsuranceApiSlice";
import LoadingSpinner from "components/Spinner/Spinner";
import PersonalAccidentForm from "./Form/PersonalAccidentForm";
import HomeCoverForm from "./Form/HomeCoverForm";
import CurrencyConverter from "utils/CurrencyConverter";
import { toast } from "react-hot-toast";
import { setPin, setSuccess } from "features/Services/ServicesSlice";

const insuranceData = [
  {
    imageSrc: "/Services/selectInsurance.svg",
    title: "Third Party Motor Insurance Universal Insurance",
  },
  {
    imageSrc: "/Services/selectInsurance.svg",
    title: "Health Insurance - HMO",
  },
  {
    imageSrc: "/Services/selectInsurance.svg",
    title: "Personal Accident Insurance",
  },
  {
    imageSrc: "/Services/selectInsurance.svg",
    title: "Home Cover Insurance",
  },
];

function formatDate(date: any) {
  if (date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1.
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
}

const InsuranceService = () => {
  const [step, setStep] = React.useState<"1" | "2" | "3" | "4" | "5">("1");
  const [steps, setSteps] = React.useState<string[]>(["1"]);
  const { setCurrentPagename } = React.useContext(DashboardContext);
  const [title, setTitle] = React.useState<string>(
    "Third Party Motor Insurance Universal Insurance"
  );
  const { data, isLoading } = useGetInsuranceProviderQuery(undefined);
  const [providers, setProviders] = React.useState<any>([]);
  const dispatch = useAppDispatch();
  const { pin } = useAppSelector((state: any) => state.services as any);
  const { insuranceDetails } = useAppSelector(
    (state: any) => state.insuranceService as any
  );

  const wallet = useAppSelector((state: any) => state.wallet);

  //use form
  const { control, setValue, handleSubmit } = useForm({});

  const [buyInsurance, { isLoading: isLoadingBuyInsurance }] =
    useBuyInsuranceMutation();

  React.useEffect(() => {
    if (data) {
      setProviders(data?.content);
      dispatch(setInsuranceProvider(data?.content));
    }
  }, [data, dispatch]);

  const onSubmit = async (data: any) => {
    if (step === "1") {
      dispatch(setInsuranceDetails({}));
      handleNext();
    }
    if (step === "2") {
      dispatch(setInsuranceDetails(data));
      handleNext();
    }
    if (step === "3") {
      handleNext();
    }
    if (step === "5") {
      const inputData1 = {
        serviceID: insuranceDetails?.variation?.serviceID,
        variation_code: insuranceDetails?.variation?.value,
        billersCode: insuranceDetails?.Plate_Number,
        phone: insuranceDetails?.phone,
        Insured_Name: insuranceDetails?.Insured_Name,
        Engine_Number: insuranceDetails?.Engine_Number,
        Chasis_Number: insuranceDetails?.Chasis_Number,
        Plate_Number: insuranceDetails?.Plate_Number,
        Vehicle_Color: insuranceDetails?.Vehicle_Color,
        Vehicle_Model: insuranceDetails?.Vehicle_Model,
        Year_of_Make: insuranceDetails?.Year_of_Make,
        Contact_Address: insuranceDetails?.Contact_Address,
        pin: pin?.join(""),
        variation_amount: insuranceDetails?.variation?.variation_amount,
        slug_id: wallet.paymentSlug,
      };

      const inputData2 = {
        serviceID: insuranceDetails?.variation?.serviceID,
        variation_code: insuranceDetails?.variation?.value,
        billersCode: insuranceDetails?.full_name,
        phone: insuranceDetails?.phone,
        variation_amount: insuranceDetails?.variation?.variation_amount,
        full_name: insuranceDetails?.full_name,
        address: insuranceDetails?.address,
        selected_hospital: insuranceDetails?.selected_hospital?.value,
        Passport_Photo: insuranceDetails?.Passport_Photo,
        date_of_birth: insuranceDetails?.date_of_birth,
        extra_info: insuranceDetails?.extra_info,
        pin: pin?.join(""),
        renewal: "off",
        slug_id: wallet.paymentSlug,
      };

      const inputData3 = {
        serviceID: insuranceDetails?.variation?.serviceID,
        variation_code: insuranceDetails?.variation?.value,
        billersCode: insuranceDetails?.full_name,
        phone: insuranceDetails?.phone,
        amount: insuranceDetails?.variation?.variation_amount,
        full_name: insuranceDetails?.full_name,
        address: insuranceDetails?.address,
        next_kin_name: insuranceDetails?.next_kin_name,
        next_kin_phone: insuranceDetails?.next_kin_phone,
        business_occupation: insuranceDetails?.business_occupation,
        pin: pin?.join(""),
        slug_id: wallet.paymentSlug,
        variation_amount: insuranceDetails?.variation?.variation_amount,
      };

      const inputData4 = {
        serviceID: insuranceDetails?.variation?.serviceID,
        variation_code: insuranceDetails?.variation?.value,
        billersCode: insuranceDetails?.full_name,
        phone: insuranceDetails?.phone,
        amount: insuranceDetails?.variation?.variation_amount,
        full_name: insuranceDetails?.full_name,
        address: insuranceDetails?.address,
        type_building: insuranceDetails?.type_building,
        business_occupation: insuranceDetails?.business_occupation,
        date_of_birth: insuranceDetails?.date_of_birth,
        pin: pin?.join(""),
        slug_id: wallet.paymentSlug,
        variation_amount: insuranceDetails?.variation?.variation_amount,
      };

      let inputData: any = {};
      if (insuranceDetails?.selected_hospital) {
        inputData = inputData2;
      } else if (insuranceDetails?.Chasis_Number) {
        inputData = inputData1;
      } else if (insuranceDetails?.type_building) {
        inputData = inputData4;
      } else {
        inputData = inputData3;
      }

      if (wallet.paymentSlug === 2 && wallet.signature) {
        inputData.signature = wallet.signature;
      }

      try {
        const res = await buyInsurance(inputData).unwrap();
        if (res) {
          dispatch(setPin([]));
          dispatch(setSuccess(true));
        }
      } catch (error: any) {
        // { status: 500, data: { error: 'Undefined variable $secret_key' } }
        if (error?.data?.error) {
          if (error?.data?.error) {
            toast.error(error?.data?.error);
          } else if (error?.originalStatus === 429) {
            toast.error("Too many requests. Please try again later");
          } else {
            toast.error("An error occured ");
          }
        }
      }
    }
  };

  //handle insurance name
  const handleInsuranceName = (value: string) => {
    setTitle(value);
  };

  let heading = (
    <Heading
      title={"Third Party Motor Insurance - Universal Insurance"}
      desc="Pay for third party motor insurance-"
      desc2="universal insurance conveniently & easily."
    />
  );
  let insuranceForm = (
    <ThirdPartyForm
      provider={providers?.find(
        (provider: any) =>
          provider?.name === "Third Party Motor Insurance - Universal Insurance"
      )}
      control={control}
      setValue={setValue}
    />
  );
  if (title?.trim() === "Health Insurance - HMO"?.trim()) {
    heading = (
      <Heading
        title={"Health Insurance - HMO"}
        desc="Pay for health insurance - hmo"
        desc2="conveniently & easily."
      />
    );
    insuranceForm = (
      <HealthInsuranceForm
        provider={providers?.find(
          (provider: any) =>
            provider?.name?.trim() === "Health Insurance - HMO"?.trim()
        )}
        control={control}
        setValue={setValue}
      />
    );
  } else if (title?.trim() === "Personal Accident Insurance"?.trim()) {
    insuranceForm = (
      <PersonalAccidentForm
        provider={providers?.find(
          (provider: any) =>
            provider?.name?.trim() === "Personal Accident Insurance"?.trim()
        )}
        control={control}
        setValue={setValue}
      />
    );
    heading = (
      <Heading
        title={"Personal Accident Insurance"}
        desc="Pay for personal accident insurance"
        desc2="conveniently & easily."
      />
    );
  } else if (title?.trim() === "Home Cover Insurance"?.trim()) {
    insuranceForm = (
      <HomeCoverForm
        provider={providers?.find(
          (provider: any) =>
            provider?.name?.trim() === "Home Cover Insurance"?.trim()
        )}
        control={control}
        setValue={setValue}
      />
    );
    heading = (
      <Heading
        title={"Home Cover Insurance"}
        desc="Pay for home cover insurance"
        desc2="conveniently & easily."
      />
    );
  }

  const handleNext = () => {
    setSteps([...steps, step]);

    if (step === "1") {
      setStep("2");
    }
    if (step === "2") {
      setStep("3");
    }
    if (step === "3") {
      setStep("4");
    }
    if (step === "4") {
      setStep("5");
    }
  };

  const handleBack = () => {
    setSteps(steps.slice(0, -1));

    if (step === "1") {
      setStep("1");
    }

    if (step === "2") {
      setStep("1");
    }
    if (step === "3") {
      setStep("2");
    }
    if (step === "4") {
      setStep("3");
    }
    if (step === "5") {
      setStep("4");
    }
  };
  if (step === "1") {
    setCurrentPagename("Insurance");
  }
  let text = "All Services";
  if (step === "2") {
    text = "Type";
    setCurrentPagename("Buy Insurance");
  }
  if (step === "3") {
    text = "Insurance";
    setCurrentPagename("Confirm");
  }
  if (step === "4") {
    text = "Confirm";
    setCurrentPagename("Payment options");
  }
  if (step === "5") {
    text = "Payment Options";
    setCurrentPagename("Transaction PIN");
  }

  return (
    <div className={style.data}>
      {isLoadingBuyInsurance ? <LoadingSpinner /> : null}

      <div className="lg:pl-[2.5rem] pl-[1rem]" onClick={handleBack}>
        <BackButton
          text={text}
          link={step === "1" ? "/services" : "/services?category=insurance"}
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className={style.data__section}>
        {step === "1" ? (
          <Step1 handleClick={handleInsuranceName} providers={providers} />
        ) : null}

        {step === "2" ? (
          <Step2
            heading={heading}
            steps={steps}
            insuranceForm={insuranceForm}
          />
        ) : null}

        {step === "3" ? <Step3 steps={steps} /> : null}

        {step === "4" ? <Step4 handleNext={handleNext} steps={steps} /> : null}
        {step === "5" ? <Step5 /> : null}

        <div className="grid place-content-center place-items-center">
          {step !== "5" && step !== "4" ? (
            <button type="submit" className="btn-primary">
              Continue
            </button>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default InsuranceService;

const Step1 = ({
  handleClick,
  providers,
}: {
  handleClick: (value: string) => void;
  providers: any[];
}) => {
  return (
    <div>
      <Heading
        title={"Select type of Insurance"}
        desc="Pay for insurance bills safely, conveniently & easily."
        desc2=" You can pay anytime and anywhere!"
      />
      <ListInsurance
        providers={providers}
        handleClick={handleClick}
        data={insuranceData}
      />
    </div>
  );
};

const Step2 = ({
  steps,
  insuranceForm,
  heading,
}: {
  steps: string[];
  insuranceForm: any;
  heading: any;
}) => {
  return (
    <div>
      <div></div>
      {heading}
      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Insurance Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>

      {insuranceForm}
    </div>
  );
};

const Step3 = ({ steps }: { steps: string[] }) => {
  const [toggle, setToggle] = React.useState<boolean>(true);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const { insuranceDetails } = useAppSelector(
    (state: any) => state.insuranceService as any
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setAmountToFund(
        CurrencyConverter(insuranceDetails?.variation?.variation_amount)
      )
    );
    // setAmountToFund
  }, [insuranceDetails, dispatch]);

  const confirmHospital = [
    {
      title: "Insurance Type",
      value: insuranceDetails?.variation?.label,
    },
    {
      title: "Selected Hospital",
      value: insuranceDetails?.selected_hospital?.label,
    },
    {
      title: "Phone Number",
      value: insuranceDetails?.phone,
    },
    {
      title: "Full Name",
      value: insuranceDetails?.full_name,
    },
    {
      title: "Amount",
      value: "N" + insuranceDetails?.variation?.variation_amount,
    },
    {
      title: "Address",
      value: insuranceDetails?.address,
    },
    {
      title: "Date of Birth",
      value: insuranceDetails?.date_of_birth,
    },
    {
      title: "Service Fee",
      value: "NO",
    },
    {
      title: "Total",
      value: "N" + insuranceDetails?.variation?.variation_amount,
    },
  ];

  const confirmThirdparty = [
    {
      title: "Insurance Type",
      value: insuranceDetails?.variation?.label,
    },
    {
      title: "Phone Number",
      value: insuranceDetails?.phone,
    },
    {
      title: "Insured Name",
      value: insuranceDetails?.Insured_Name,
    },
    {
      title: "Chasis Number",
      value: insuranceDetails?.Chasis_Number,
    },
    {
      title: "Plate Number",
      value: insuranceDetails?.Plate_Number,
    },
    {
      title: "Vehicle Make",
      value: insuranceDetails?.Vehicle_Make,
    },
    {
      title: "Vehicle Model",
      value: insuranceDetails?.Vehicle_Model,
    },
    {
      title: "Vehicle Color",
      value: insuranceDetails?.Vehicle_Color,
    },
    {
      title: "Year of Make",
      //    format date
      value: insuranceDetails?.Year_of_Make,
    },
    {
      title: "Engine Number",
      value: insuranceDetails?.Engine_Number,
    },
    {
      title: "Amount",
      value: "N" + insuranceDetails?.variation?.variation_amount,
    },
    {
      title: "Service Fee",
      value: "NO",
    },
    {
      title: "Total",
      value: "N" + insuranceDetails?.variation?.variation_amount,
    },
  ];

  const confirmHomeCover = [
    {
      title: "Insurance Type",
      value: insuranceDetails?.variation?.label,
    },
    {
      title: "Phone Number",
      value: insuranceDetails?.phone,
    },
    {
      title: "Full Name",
      value: insuranceDetails?.full_name,
    },
    {
      title: "Address",
      value: insuranceDetails?.address,
    },
    {
      title: "Business / Occupation",
      value: insuranceDetails?.business_occupation,
    },
    {
      title: "Type of Building",
      value: insuranceDetails?.type_building,
    },
    {
      title: "Date of Birth",
      value: insuranceDetails?.date_of_birth,
    },
    {
      title: "Amount",
      value: "N" + insuranceDetails?.variation?.variation_amount,
    },
    {
      title: "Service Fee",
      value: "NO",
    },
    {
      title: "Total",
      value: "N" + insuranceDetails?.variation?.variation_amount,
    },
  ];
  const confirmPersonalAccident = [
    {
      title: "Insurance Type",
      value: insuranceDetails?.variation?.label,
    },
    {
      title: "Phone Number",
      value: insuranceDetails?.phone,
    },
    {
      title: "Full Name",
      value: insuranceDetails?.full_name,
    },
    {
      title: "Address",
      value: insuranceDetails?.address,
    },
    {
      title: "Business / Occupation",
      value: insuranceDetails?.business_occupation,
    },
    {
      title: "Next of KIN",
      value: insuranceDetails?.next_kin_name,
    },
    {
      title: "Next of KIN Phone Number",
      value: insuranceDetails?.next_kin_phone,
    },
    {
      title: "Amount",
      value: "N" + insuranceDetails?.variation?.variation_amount,
    },
    {
      title: "Service Fee",
      value: "NO",
    },
    {
      title: "Total",
      value: "N" + insuranceDetails?.variation?.variation_amount,
    },
  ];

  let confirmData: any[] = [];

  if (insuranceDetails?.selected_hospital) {
    confirmData = confirmHospital;
  } else if (insuranceDetails?.Chasis_Number) {
    confirmData = confirmThirdparty;
  } else if (insuranceDetails?.type_building) {
    confirmData = confirmHomeCover;
  } else {
    confirmData = confirmPersonalAccident;
  }

  return (
    <div>
      <Heading
        title={"Confirm Insurance Purchase"}
        desc="Please confirm your insurance purchase"
      />

      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Insurance Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>
      <ConfirmForm data={confirmData} src="/Services/selectInsurance.svg" />
    </div>
  );
};

const Step4 = ({
  steps,
  handleNext,
}: {
  steps: string[];
  handleNext: () => void;
}) => {
  return (
    <div>
      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Insurance Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>
      <ServicesPayment onClick={handleNext} />
    </div>
  );
};

const Step5 = () => {
  const confirmDetails = useAppSelector(
    (state) => state.insuranceService?.insuranceDetails as any
  );

  return (
    <div className="grid place-content-center pt-[4rem]">
      <PinForm
        amount={`Pay ${CurrencyConverter(
          confirmDetails?.variation?.variation_amount
        )}`}
        successMessage={{
          title: "Insurance Purchase Successful",
          message: (
            <>
              {`Your have successfully purchased ${confirmDetails?.variation?.name} insurance of `}
              <span>
                {CurrencyConverter(confirmDetails?.variation?.variation_amount)}
              </span>
            </>
          ),
          buttonText: "Dashboard",
          link: "/services",
        }}
      />
    </div>
  );
};
