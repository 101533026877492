import React  , {useContext} from 'react';
import Heading from './Heading/Heading';
import BackButton from 'components/buttons/backbutton/BackButton';
import ProgressTracker from 'components/ProgressTracker/ProgressTracker2';
import style from './generalservicestyle.module.scss'
import { EducationForm } from './Form/InputForm'
import PinForm from './Form/EnterPin';
import ConfirmForm from './Form/ConfirmForm';

import ServicesPayment from 'components/paymentOptions/ServicesPayment';
import DashboardContext from 'context/Dashboard/DashboardContext';



const confirmData = [
    {
        "title": "Product",
        "value": "WAEC PIN"
    },
    {
        "title": "Unit Price",
        "value": "N5000"
    },
    {
        "title": "Quantity",
        "value": "10"
    },
    {
        "title": "Sub Total",
        "value": "N5000.00"
    },
    {
        "title": "Convenience Fee",
        "value": "N100"
    },


    {
        "title": "Total",
        "value": "N5,100.00"
    }

]



const EducationService = () => {
    const [step, setStep] = React.useState<'1' | "2" | "3" | "4">("1")
    const [steps, setSteps] = React.useState<string[]>(["1"])
    const {setCurrentPagename} = useContext(DashboardContext)

    const handleNext = () => {
        setSteps([...steps, step])


        if (step === "1") {
            setStep("2")

        }
        if (step === "2") {
            setStep("3")

        }
        if (step === "3") {
            setStep("4")
        }

    }

    const handleBack = () => {
        setSteps(steps.slice(0, -1))


        if (step === "1") {
            setStep("1")

        }

        if (step === "2") {
            setStep("1")

        }
        if (step === "3") {
            setStep("2")
        }
        if (step === "4") {
            setStep("3")

        }



    }


    if(step === "1"){
        setCurrentPagename("Buy Exam PIN")


    }
    let text  = "All Services"
    if (step === "2") {
        text = "Education"
        setCurrentPagename("Confirm")
    }
    if (step === "3") {
        text = "Confirm"
        setCurrentPagename("Payment Options")


    }
    if (step === "4") {
        text = "Payment options"
        setCurrentPagename("Transaction PIN")
    }

    return (
        <div className={style.data} >
            <div className='lg:pl-[2.5rem] pl-[1rem]' onClick={handleBack}>
                <BackButton text={
                    text

                } link={
                    step === "1" ? "/services" : "/services?category=education"
                } />
            </div>

            <section className={style.data__section}>

                {step === "1" ? <Step1 steps={steps} /> : null}

                {step === "2" ? <Step2 steps={steps} /> : null}

                {step === "3" ? <Step3 steps={steps} handleNext = {handleNext} /> : null}



                {
                    step === "4" ? <Step4 /> : null
                }

                <div className='grid place-content-center place-items-center'>
                {(step !== "4" && step !== "3") ? <button onClick={handleNext} className='btn-primary'>Continue</button> : null}

                </div>

            </section>

        </div>
    )

}

export default EducationService




const Step1 = ({
    steps
}: {
    steps: string[]
}) => {
    return (
        <div>
             <Heading title={"Pay for Exam PIN"} desc="Pay for Exam PINs safely, conveniently & easily." desc2="You can pay anytime and anywhere!" />
            <div className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}>
                <ProgressTracker levels={["Education Options", "Confirm Details", "Select Payment", "Enter Pin & Pay"]} steps={steps} />
            </div>

            <EducationForm />
        </div>
    )
}




const Step2 = ({
    steps
}: {
    steps: string[]
}) => {

const [toggle, setToggle] = React.useState<boolean>(true)

const handleToggle = () => {
    setToggle(!toggle)

}

    return (
        <div>
            <Heading title={"Confirm Exam PIN Purchase"} desc="Please confirm your PIN purchase" />

            <div className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}>
                <ProgressTracker levels={["Education Options", "Confirm Details", "Select Payment", "Enter Pin & Pay"]} steps={steps} />
            </div>
            <ConfirmForm handleSaveBeneficiary={handleToggle} saveBeneficiary={
                toggle
            } data={confirmData} src='/services/electricityImage.svg' />
        </div>
    )

}


const Step3 = ({
    steps , handleNext
}: {
    steps: string[] , handleNext : () => void
}) => {
    return (

        <div>

            <div className={`${style.progress__tracker} grid place-content-center w-full place-items-center`} >
                <ProgressTracker levels={["Education Options", "Confirm Details", "Select Payment", "Enter Pin & Pay"]} steps={steps} />
            </div>
            <ServicesPayment  onClick={handleNext} />

        </div>
    )
}


const Step4 = () => {
    return (
        <div className='grid place-content-center place-items-center pt-[2.5rem]' >

            <PinForm amount='Pay N50,000' />
        </div >
    )
}