import React from "react";
// import DatePicker from "react-datepicker";
import style from "./servicesinput.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import { useController } from "react-hook-form";
// import { RxCalendar } from "react-icons/rx";
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Grid,
  Text,
  // Icon,
  // InputGroup,
  // InputRightElement,
} from "@chakra-ui/react";
// import { useEffect } from "react";

const DateInput = ({
  control,
  defaultValue,
  name,
  label,
  placeholder,
  setValue,
  open,
  rules,
  dateFormat = "yyyy",
  showYearPicker = true,
}: {
  control: any;
  defaultValue: any;
  name: string;
  label: string;
  placeholder?: string;
  setValue?: (value: any, name: string) => void;
  open?: boolean;
  rules?: any;
  dateFormat?: string;
  showYearPicker?: boolean;
}) => {
  const {

    fieldState: { error },
    field: { onChange, value },
  } = useController({
    control,
    name,
    // defaultValue,
    rules,
  });

  return (
    <FormControl>
      <Grid>
        <FormLabel>
          <Text
            color={"var(--dark-text"}
            fontWeight={"500"}
            fontSize={".875rem"}
          >
            {label}
          </Text>
        </FormLabel>
        <FormControl isInvalid={error?.message ? true : false}>
          <Grid pos={"relative"}>
            <Input
              // as={DatePicker}
              // placeholderText={"Select Date"}
              onChange={onChange}
              value={value}
              // selected={
              //   value
              //     ? value
              //     : defaultValue
              //     ? new Date(defaultValue)
              //     : new Date()
              // }
              _placeholder={{
                color: "var(--text-1)",
                fontWeight: "500",
              }}
              // dateFormat={dateFormat}
              className={`${style.input__input} outline-none border w-full
                 active:border-[#246BFD] focus:border-[#246BFD] ${
                   value ? "border-[#246BFD]" : "border-[var(--gray-text)]"
                 } `}
              fontSize={".875rem"}
              borderRadius={"0.625rem"}
              fontWeight={"500"}
              padding={"1.5rem"}
              focusBorderColor="#246BFD"
              type="date"
              // maxDate={new Date()}
              // showYearPicker = {showYearPicker}
            />

            {/* <Icon
              right={"5"}
              bottom={"30%"}
              as={RxCalendar}
              color={"#A2A2A8"}
              pos={"absolute"}
            /> */}
          </Grid>
          <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
      </Grid>
    </FormControl>
  );
};

export default DateInput;
