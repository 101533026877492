// import React from "react";
// import Card from "./Card";
// import style from "./cardlist.module.scss";
// import Pagination from "components/Pagination/Pagination";
// import { useAppDispatch } from "hooks/redux";
// import { setPage } from "features/Services/GiftCardService/GiftCardServiceSlice";
// import { useAppSelector } from "hooks/redux";

// interface CardListProp {
//   data: {
//     image_path: string;
//     name: string;
//     price: string;
//   }[];
//   fullData?: any;
//   handleClick: (item: {
//     image_path: string;
//     name: string;
//     price: string;
//   }) => void;
// }

// const CardList: React.FC<CardListProp> = ({ handleClick }) => {
//     const { products : data, fullData } = useAppSelector(
//     (state) => state.giftCardService
//   );

//   const pageCount = fullData?.meta?.total / fullData?.meta?.per_page;
//   const dispatch = useAppDispatch();

//   const onChange = (e: any) => {
//     dispatch(setPage(e.selected + 1));
//   };

//   return (
//     <>
//       <main className={style.cardlist}>
//         {data?.map(
//           (
//             item: { image_path: string; name: string; price: string },
//             index: number
//           ) => {
//             return <Card key={index} handleClick={handleClick} item={item} />;
//           }
//         )}
//       </main>
//       <div className="flex justify-end items-center mt-10">
//         <Pagination pageCount={pageCount} onPageChange={onChange} />
//       </div>
//     </>
//   );
// };

// export default CardList;

import React, { useEffect, useState, useContext, useRef } from "react";
import Card from "./Card";
import style from "./cardlist.module.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  setFullData,
  setPage,
  setProducts,
} from "features/Services/GiftCardService/GiftCardServiceSlice";
import { Box, Button, Flex } from "@chakra-ui/react";
import DashboardContext from "context/Dashboard/DashboardContext";
import { motion } from "framer-motion";

const BoxMotion = motion(Box);

interface CardListProp {
  data: {
    image_path: string;
    name: string;
    price: string;
  }[];
  fullData?: any;
  handleClick: (item: {
    image_path: string;
    name: string;
    price: string;
  }) => void;
}

const CardList: React.FC<CardListProp> = ({ handleClick }) => {
  const { currentId, setCurrentId } = useContext(DashboardContext);
  const ref = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //  if (setCurrentId ) setCurrentId(112)

  // }, [setCurrentId]);

  const {
    products: data = [],
    fullData,
    name,
  } = useAppSelector((state) => state.giftCardService);

  // console.log(data, "data");

  const dispatch = useAppDispatch();
  const [pageData, setPageData] = useState<any[]>([]);



  useEffect(() => {
    if (name) {
      setPageData(data);
    } else {
      if (data?.length > 0) {
        setPageData((prev) => [...prev, ...data]);
      }
    }
    // if (data?.length > 0) setPageData((prev) => [...prev, ...data]);
  }, [data, name]);

  useEffect(() => {
    // setPageData(() => []);
    // dispatch(setFullData({}));
    // dispatch(setProducts([]));
    dispatch(setPage(1));
  }, [currentId, dispatch, ref]);

  const fetchMoreData = () => {
    if (fullData?.meta?.current_page < fullData?.meta?.last_page) {
      dispatch(setPage(fullData.meta.current_page + 1));
    }
  };

  return (
    <BoxMotion ref={ref}>
      <InfiniteScroll
        dataLength={pageData?.length}
        hasMore={fullData?.meta?.current_page < fullData?.meta?.last_page}
        next={fetchMoreData}
        scrollThreshold={0.1}
        loader={
          <Flex w={"100%"} justify={"center"} align={"center"} mt={"3rem"}>
            <Button
              isLoading={true}
              colorScheme="blue"
              loadingText="Fetching..."
            ></Button>
          </Flex>
        }
        endMessage={
          <p className="text-center mt-10 font-semibold">
            {data?.length > 0 ? "You have seen all the cards" : ""}
          </p>
        }
        scrollableTarget="scrollable"
        style={{ overflowY: "auto" }}

        // className={style.scrollable}

      >
        <Box
          className={style.cardlist}
          padding={{
            base: ".5rem",
            lg: "1rem",
          }}
        >
          {pageData?.map((item, index) => (
            <Card key={index} handleClick={handleClick} item={item} />
          ))}
        </Box>
      </InfiniteScroll>
    </BoxMotion>
  );
};

export default CardList;
